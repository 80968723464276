import api from '../../axios'
import { BoxIT, UpdateMaterialsIT, UpdateServicesIT } from './interface'

export default class BoxService {
    async getBoxList() {
        const resp = await api.get('/box/active')
        return resp
    }

    async getBoxBySlug(slug: string) {
        const resp = await api.get(`/box/active?slug=${slug}`)
        return resp
    }

    async addUserInBox(_id: string, userId: string) {
        const data = { userId }
        const resp = await api.put(`/box/user/${_id}`, data)
        return resp
    }

    async create(boxes: Array<BoxIT>, address: string, clinicName: string) {
        const boxesReturn = boxes.map(async (box: BoxIT, index: number) => {
            const formData = new FormData()
            formData.append('name', `${clinicName} - BOX N° ${index + 1}`)
            formData.append('boxSize', box.boxSize.toString())
            formData.append('address', address)
            box.files?.forEach((file: any) => {
                formData.append('file', file)
            })
            const resp = await api.post('/box', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
            })
            const { data }: any = resp.data
            return data
        })

        const boxesCreated = await Promise.all(boxesReturn)
        return boxesCreated
    }

    async addDocument(_id: string, files: Array<File>) {
        const formData = new FormData()
        files?.forEach((file: any) => {
            formData.append('file', file)
        })
        const resp = await api.post(`/box/document/${_id}`, formData, {
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        })
        const { data }: any = resp.data

        return [data]
    }

    async updateMaterials(data: Array<UpdateMaterialsIT>) {
        const boxesReturn = data?.map(async (item: UpdateMaterialsIT) => {
            const payload = {
                name: item.boxName,
                boxSize: item.boxSize,
                materials: item.materials,
            }
            const resp = await api.put(`/box/${item.id}`, payload)
            const { data }: any = resp.data
            return data
        })
        const boxesUpdated = await Promise.all(boxesReturn)
        return boxesUpdated
    }

    async updateServices(data: Array<UpdateServicesIT>) {
        const boxesReturn = data?.map(async (item: UpdateServicesIT) => {
            const payload = {
                name: item.boxName,
                boxSize: item.boxSize,
                services: item.services,
                parkingDentist: item.parkingDentist,
                parkingClient: item.parkingClient,
            }
            const resp = await api.put(`/box/${item.id}`, payload)
            const { data }: any = resp.data
            return data
        })
        const boxesUpdated = await Promise.all(boxesReturn)
        return boxesUpdated
    }
}
