// Modules
import React, { useState, useEffect } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import { toastDanger } from '../../../redux/actions/toast/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import styled from 'styled-components'

//Icons
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

// Components
import Card from '../../../components/Card'
import MapsAutoComplete from './PlacesAutocomplete'

// Images
import { FormContentWrapper } from '../../../components'

// Routes
import history from '../../../services/history'

// Redux
import { SavingBoxAddress } from '../../../redux/actions/registerForms/action'
import { useLocation } from 'react-router-dom'

const lib: any = ['places']
const mapStyles = {
    height: '20rem',
    width: '100%',
}

function Address() {
    // States
    const [state, setState] = useState('')
    const [currentPosition, setCurrentPosition] = useState({
        lat: 0,
        lng: 0,
    })

    // Reducers
    const {
        registerForms: { boxAddress: address },
    }: any = useSelector(state => state)

    // Dispatch
    const dispatch = useDispatch()

    // UseEffects
    useEffect(() => {
        if (address?.name && address?.position) {
            setState(address?.name)
            setCurrentPosition(address?.position)
        }
    }, [address])

    // Routes
    const { search } = useLocation()
    const summary = search?.split('=')[1]

    // Methods
    const handleChange = (newValue: any) => {
        setState(newValue)
    }

    const isValid = (addressName: any, mapCoordinates: any) => {
        if (addressName === '') {
            dispatch(toastDanger('Tienes que escribir una dirección!'))
            return false
        } else if (addressName.length < 10) {
            dispatch(toastDanger('Dirección muy corta!'))
            return false
        } else if (!mapCoordinates.lat && !mapCoordinates.lng) {
            dispatch(toastDanger('Dirección no encontrada!'))
            return false
        }
        return true
    }

    const handleSubmit = () => {
        if (isValid(state, currentPosition)) {
            dispatch(SavingBoxAddress({ name: state, position: currentPosition }))
            if (summary) {
                history.push('/onboarding/summary')
            } else {
                history.push('/onboarding/business-info')
            }
        }
    }

    return (
        <FormContentWrapper>
            <Card
                mark={''}
                title={
                    <>
                        <FontAwesomeIcon icon={faQuestion} style={{ transform: 'rotate(180deg)' }} />
                        Escribe la dirección donde se encuentra el Box que quieres arrendar
                        <FontAwesomeIcon icon={faQuestion} />
                    </>
                }
                current={1}
                backLink={'/register'}
                onSubmit={handleSubmit}
            >
                <LoadScript googleMapsApiKey={'AIzaSyBGnSNtaSmSt6S132pbwEYPmXQthC9xtis'} libraries={lib}>
                    <MapsAutoComplete
                        setPosition={setCurrentPosition}
                        handleChange={handleChange}
                        state={state}
                        setState={setState}
                    />
                    {currentPosition.lat || currentPosition.lng ? (
                        <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={currentPosition}>
                            {currentPosition.lat && <Marker position={currentPosition} />}
                        </GoogleMap>
                    ) : null}
                </LoadScript>
            </Card>
        </FormContentWrapper>
    )
}

export default Address
