// Modules
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Drawer, List, ListItem, ListItemText } from '@mui/material'

// Images
import FlipItLogo from '../assets/image/svg/logo.svg'

// Icons
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

const HeaderDesktop = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items: center;
        background: ${theme?.colors?.white};
        -moz-box-shadow:    0px 10px 20px rgb(0 0 0 / 10%);
        -webkit-box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
        box-shadow:         0px 10px 20px rgb(0 0 0 / 10%);
        position: fixed;
        top:0;
        left:0;
        z-index: 1000;

        @media screen and (max-width: 768px){
            display: none;
        }
    `}
`

const LogoWrapper = styled('div')`
    ${({ theme }) => `    
        display: flex;
        align-item: flex-end;
        justify-content: center;
        width: 20%;
    `}
`

const Logo = styled('img')`
    ${({ theme }) => `
        height: 2.5rem;
        display: flex;
        list-style: none;
        cursor: pointer;
    `}
`

const ListWrapper = styled('div')`
    ${({ theme }) => `    
        display: flex;
        align-item: center;
        justify-content: center;
        width: 60%;
    `}
`

const HeaderList = styled('ul')`
    ${({ theme }) => `
        display: flex;
        list-style: none;
    `}
`

const HeaderListItem = styled('li')`
    ${({ theme }) => `
        color: ${theme?.colors?.secondBase};
        list-style: none;
        font-size: ${theme?.fontSizes?.font15};
        padding: ${theme?.spacing?.spc10} ${theme?.spacing?.spc10};
        font-family: raleway;
        cursor: pointer;

        &:hover{
            color: ${theme?.colors?.thirdBase};
        }
    `}
`

const ButtonWrapper = styled('div')`
    ${({ theme }) => `    
        display: flex;
        align-item: center;
        justify-content: center;
        width: 20%;
    `}
`

const ButtonRegister = styled('button')`
    ${({ theme }) => `
        background: ${theme?.colors?.base};
        color: ${theme?.colors?.white};
        padding: ${theme?.spacing?.spc12} ${theme?.spacing?.spc22};
        border: none;
        border-radius: ${theme?.spacing?.spc10};
        font-size: ${theme?.fontSizes?.font15};
        font-family: raleway;
        cursor: pointer;

        &:hover{
            background: ${theme?.colors?.secondBase};
        }
    `}
`

const HeaderMobile = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items: center;
        background: ${theme?.colors?.white};
        -moz-box-shadow:    1px 2px 5px 0.5px ${theme?.colors?.greyBase};
        -webkit-box-shadow: 1px 2px 5px 0.5px ${theme?.colors?.greyBase};
        box-shadow:         1px 2px 5px 0.5px ${theme?.colors?.greyBase};
        position: fixed;
        top:0;
        left:0;
        height: 4.5rem;
        z-index: 1000;

        @media screen and (min-width: 769px){
            display: none;
        }
    `}
`

const LogoWrapperMobile = styled('div')`
    ${({ theme }) => `
        width: 60%;
        display: flex;
    `}
`

const LinkHome = styled('a')`
    ${({ theme }) => `
        text-decoration: none;
        color: ${theme?.colors?.secondBase};
        font-family: raleway;

        &:hover{
            color: ${theme?.colors?.thirdBase};
        }
    `}
`

const LogoMobile = styled('img')`
    ${({ theme }) => `
        height: 3rem;
        display: flex;
        list-style: none;
        cursor: pointer;
        margin-left: ${theme?.spacing?.spc10};
    `}
`

const MenuIconMobileWrapper = styled('div')`
    ${({ theme }) => `
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg{
        margin-right: ${theme?.spacing?.spc10};
        cursor: pointer;
        font-size: ${theme?.fontSizes?.font30} !important;
    }
    `}
`

function Header() {
    // States
    const [open, setOpen] = useState(false)

    // Routes
    const history = useHistory()

    const toggleDrawer = (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setOpen(!open)
    }

    //Use Effect
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <HeaderDesktop>
                <LogoWrapper>
                    <a href='https://flipit.gux.cl/' style={{ textDecoration: 'none' }}>
                        <Logo src={FlipItLogo} alt='Flipit' />
                    </a>
                </LogoWrapper>
                <ListWrapper>
                    <HeaderList>
                        <HeaderListItem onClick={() => history.push('/')}></HeaderListItem>
                        <HeaderListItem>
                            <LinkHome href='https://flipit.gux.cl/'>INICIO</LinkHome>
                        </HeaderListItem>
                        <HeaderListItem onClick={() => history.push('/')}>ARRENDAR BOX</HeaderListItem>
                    </HeaderList>
                </ListWrapper>
                <ButtonWrapper>
                    <ButtonRegister onClick={() => history.push('/onboarding')}>PUBLICA TU BOX</ButtonRegister>
                </ButtonWrapper>
            </HeaderDesktop>

            <HeaderMobile>
                <LogoWrapperMobile>
                    <LogoMobile src={FlipItLogo} alt='Flipit' onClick={() => history.push('/')} />
                </LogoWrapperMobile>
                <MenuIconMobileWrapper onClick={toggleDrawer}>
                    {!open ? <FontAwesomeIcon icon={faBars} /> : null}
                </MenuIconMobileWrapper>
                <Drawer anchor='left' open={open} onClose={toggleDrawer}>
                    <List>
                        <ListItem button key={'logo'}>
                            <LogoMobile
                                src={FlipItLogo}
                                alt='Flipit'
                                onClick={() => history.push('/')}
                                style={{ marginLeft: '0' }}
                            />
                            <FontAwesomeIcon
                                icon={faTimes}
                                style={{ marginLeft: '4rem', fontSize: '1.5rem' }}
                                onClick={() => setOpen(false)}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText>
                                <LinkHome href='https://flipit.gux.cl/'>INICIO</LinkHome>
                            </ListItemText>
                        </ListItem>

                        <ListItem onClick={() => history.push('/')}>
                            <ListItemText primary={'ARRENDAR BOX'} />
                        </ListItem>

                        <ListItem
                            button
                            style={{ justifyContent: 'center' }}
                            onClick={() => history.push('/onboarding')}
                        >
                            <ButtonRegister style={{ width: '100%' }}>PUBLICA TU BOX</ButtonRegister>
                        </ListItem>
                    </List>
                </Drawer>
            </HeaderMobile>
        </>
    )
}

export default Header
