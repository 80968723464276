import { Provider } from 'react-redux';
import AppRouter from './routes/AppRouter';
import store from './redux/store'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from './styles/theme'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store} >
        <ToastContainer limit={4}/>
        <GlobalStyle />
      <AppRouter />
    </Provider>
    </ThemeProvider>
  );
}

export default App;
