// Modules
import styled from 'styled-components'

import ProgressItem from './Item'

const ProgressWrapper = styled('div')`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    margin-bottom: 4px;
  `}
`

const items = [1, 2, 3, 4, 5, 6, 7, 8]

interface ProgressIT {
  current: number
}

function Progress({ current }: ProgressIT) {
  return (
    <ProgressWrapper>
      {items.map((each, index) => (
        <ProgressItem key={index} isActive={index < current} isFirst={index === 0} />
      ))}
    </ProgressWrapper>
  )
}

export default Progress
