import React from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { useHistory } from 'react-router-dom'
import Gux from '../assets/image/svg/gux-white.svg'
import FooterLogo from '../assets/image/svg/logo-footer.svg'
import Logo from '../assets/image/svg/logo-white.svg'
import WhatsApp from '../assets/image/png/WhatsApp.png'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

const FooterWrapper = styled('div')`
    ${({ theme }) => `
    display: flex;
    align-items: center;
    background: ${theme?.colors?.white};
    height:auto;
    justify-content:center;
    width: 100%;
    flex-direction:column;
    `}
`
const ComponentsContainer = styled('div')`
    ${({ theme }) => `
 
    display: flex;
    align-items: center;
    background: ${theme?.colors?.white};
    height:auto;
    justify-content:center;
    width: 100%;
        @media screen and (max-width:768px){
        flex-direction:column;
        height:auto;
        }
    `}
`
const ComponentsWrapper = styled('div')`
    ${({ theme }) => `
    display:flex;
    align-items:left;
    height:auto;
    flex-direction:column;
    margin-left:${theme?.spacing?.spc45};
    margin-top:${theme?.spacing?.spc20};
    margin-bottom:${theme?.spacing?.spc20};
        svg{
            font-size:${theme?.fontSizes?.font30};
            margin-bottom:${theme?.spacing?.spc20};
        }
        @media screen and (max-width:768px){
        align-items:center;
        justify-content:center;
        margin-top:${theme?.spacing?.spc25};
        margin-left:0px;
    }
 `}
`
const LineSpace = styled('div')`
    ${({ theme }) => `
    height:auto;
    width:auto;
    display:flex;
    align-items:center;
`}
`
const FooterSubtitle = styled('p')`
    ${({ theme }) => `
    font-size:${theme?.fontSizes?.font20};
    color:${theme?.colors?.base};
    font-family:raleway;
`}
`
const FooterText = styled('p')`
    ${({ theme }) => `
    font-size:${theme?.fontSizes?.font16};
    color:${theme?.colors?.secondBase};
    margin-top:${theme?.spacing?.spc2};
    font-family:raleway;
    `}
`
const LogoWrapperMobile = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items:center;
    justify-content:center;
    `}
`
const LogoMobile = styled('img')`
    ${({ theme }) => `
    height: 4rem;
    margin-top:${theme?.spacing?.spc25};
    display: flex;
    list-style: none;
    cursor: pointer;
    `}
`
const MidiaIconsWrapper = styled('div')`
    ${({ theme }) => `
    width:100%;
    height:auto;
    display:flex;
    align-items:center;
    justify-content:center;
    background:${theme?.colors?.whiteGrey};
        svg{
            cursor:pointer;
            font-size:${theme?.fontSizes?.font27};
            color:${theme?.colors?.secondBase};
            padding:${theme?.spacing?.spc14};
            margin-top:${theme?.spacing?.spc12};  
    }
}
`}
`
const SocialLink = styled('a')`
    ${({ theme }) => `
    cursor: pointer;
    text-decoration: none;
    color: ${theme?.colors?.secondBase};  
`}
`
const BaseWrapper = styled('div')`
    ${({ theme }) => `
    width:100%;
    height:auto;
    align-items:center;
    justify-content:center;
    background:${theme?.colors?.base};
    @media screen (max-width:768px){
      height:auto;
      flex-direction:column; 
    }
`}
`
const BaseFullBack = styled('div')`
    ${({ theme }) => `
    display:flex;
    height:2rem;
    align-items:center;
    justify-content:center;
        svg{
            font-size:${theme?.fontSizes?.font16};
        }
`}
`
const BaseText = styled('p')`
    ${({ theme }) => `
    color:${theme?.colors?.white};
    font-size:${theme?.fontSizes?.font16};
    font-family:raleway-thin;
`}
`
const BaseImage = styled('img')`
    ${({ theme }) => `
    height:1rem;
    margin-left:${theme?.spacing?.spc10};
`}
`
const LinkWhatsapp = styled('a')`
    ${({ theme }) => `
    height: 3rem;
    width: 3rem;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #26cc64;
    text-decoration: none;
    margin-left:${theme?.spacing?.spc35};
    margin-bottom:${theme?.spacing?.spc10};
    border:none;
    border-radius: 100%;
`}
`
const WhatsAppContent = styled('span')`
    ${({ theme }) => `
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`}
`
const WhatsAppImage = styled('img')`
    ${({ theme }) => `
    width:3rem;
    height:3rem;
    cursor:pointer;
`}
`
const ArrowUp = styled('a')`
    ${({ theme }) => `
    height: 3rem;
    width: 3rem;
    position: fixed;
    bottom: 0;
    right: 0;
    background:#bd2666;
    text-decoration: none;
    margin-right:${theme?.spacing?.spc35};
    margin-bottom:${theme?.spacing?.spc10};
    border:none;
    border-radius: 100%;
    
`}
`
const ArrowUpContent = styled('span')`
    ${({ theme }) => `
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
      svg{
        font-size:${theme?.fontSizes?.font20};
        color:${theme?.colors?.white};
      }
  `}
`

function Footer() {
    // Routes
    const history = useHistory()

    function WindowScroll() {
        window.scroll(0, 0)
    }

    return (
        <FooterWrapper>
            <MidiaIconsWrapper>
                <LineSpace>
                    <SocialLink href='https://www.facebook.com/https://flipit.gux.cl/' target='blank'>
                        <FontAwesomeIcon icon={faFacebookF} />
                    </SocialLink>
                    <SocialLink
                        href='https://api.whatsapp.com/send/?phone=56983416851&text=Hola%21&app_absent=0'
                        target='_blank'
                    >
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </SocialLink>
                    <SocialLink href=' https://www.instagram.com/https://flipit.gux.cl/' target='_blank'>
                        <FontAwesomeIcon icon={faInstagram} />
                    </SocialLink>
                    <SocialLink href='https://www.linkedin.com/company/flipit-work/'>
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </SocialLink>
                </LineSpace>
            </MidiaIconsWrapper>
            <ComponentsContainer>
                <Grid container>
                    <Grid item xs={12} md={3} lg={3} xl={3}>
                        <ComponentsWrapper>
                            <LogoWrapperMobile>
                                <LogoMobile src={FooterLogo} alt='FooterLogo' onClick={() => history.push('/')} />
                            </LogoWrapperMobile>
                        </ComponentsWrapper>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3}>
                        <ComponentsWrapper>
                            <LineSpace>
                                <FooterSubtitle>Nuestros Box</FooterSubtitle>
                            </LineSpace>
                            <LineSpace>
                                <SocialLink onClick={() => history.push('/')}>
                                    <FooterText>Busca tu Box Ideal</FooterText>
                                </SocialLink>
                            </LineSpace>
                            <LineSpace>
                                <SocialLink onClick={() => history.push('/terminos-y-condiciones')}>
                                    <FooterText>Términos de uso</FooterText>
                                </SocialLink>
                            </LineSpace>
                        </ComponentsWrapper>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3}>
                        <ComponentsWrapper>
                            <LineSpace>
                                <FooterSubtitle>Anfritiones</FooterSubtitle>
                            </LineSpace>
                            <LineSpace>
                                <SocialLink onClick={() => history.push('/onboarding')}>
                                    <FooterText>Publica con nosostros</FooterText>
                                </SocialLink>
                            </LineSpace>
                            <LineSpace>
                                <FooterText>
                                    <SocialLink onClick={() => history.push('/terminos-y-condiciones')}>
                                        Término y condiciones
                                    </SocialLink>
                                </FooterText>
                            </LineSpace>
                        </ComponentsWrapper>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3}>
                        <ComponentsWrapper>
                            <LineSpace>
                                <FooterSubtitle>Soporte</FooterSubtitle>
                            </LineSpace>
                            <LineSpace>
                                <SocialLink href='https://https://flipit.gux.cl/#conversemos' target='_blank'>
                                    <FooterText>Conversemos</FooterText>
                                </SocialLink>
                            </LineSpace>
                            <LineSpace>
                                <SocialLink onClick={() => history.push('/terminos-y-condiciones')}>
                                    <FooterText>Políticas de privacidad</FooterText>
                                </SocialLink>
                            </LineSpace>
                        </ComponentsWrapper>
                    </Grid>
                </Grid>
            </ComponentsContainer>
            <LinkWhatsapp
                href='https://api.whatsapp.com/send/?phone=56983416851&text=Hola%21&app_absent=0'
                target='_blank'
            >
                <WhatsAppContent>
                    <WhatsAppImage src={WhatsApp} />
                </WhatsAppContent>
            </LinkWhatsapp>
            <ArrowUp onClick={WindowScroll}>
                <ArrowUpContent>
                    <FontAwesomeIcon icon={faAngleUp} />
                </ArrowUpContent>
            </ArrowUp>
            <BaseWrapper>
                <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                        <BaseFullBack>
                            <BaseText>2020</BaseText>
                            <BaseImage src={Logo} alt='Logo' />
                        </BaseFullBack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <BaseFullBack>
                            <BaseText>Powered by</BaseText>
                            <BaseImage src={Gux} alt='Gux' />
                        </BaseFullBack>
                    </Grid>
                </Grid>
            </BaseWrapper>
        </FooterWrapper>
    )
}

export default Footer
