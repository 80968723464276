import styled from 'styled-components'

const ProgressWrapper = styled('div')`
  ${({ theme }) => `
    display: flex;
    align-items: center;
  `}
`

const ProgressCicle = styled.div<Pick<Props, 'isActive'>>`
  background-color: ${({ theme, isActive }) => isActive ? theme?.colors?.base : theme?.colors?.greyLight};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  width: 25px;
  height: 25px;
  border-radius: 99px;

  @media screen and (max-width: 768px){
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
`

const ProgressLine = styled.div<Pick<Props, 'isActive'>>`
  background-color: ${({ theme, isActive }) => isActive ? theme?.colors?.base : theme?.colors?.greyLight};
  height: 4px;
  width: 100%;
`

type Props = {
  isActive: boolean;
  isFirst: boolean;
}

function ProgressItem({ isActive, isFirst }: Props) {
  return (
    <ProgressWrapper style={{
      width: `${isFirst ? 'auto' : '100%'} `
    }}>
      {!isFirst && <ProgressLine isActive={isActive} />}
      <ProgressCicle isActive={isActive}>
        {
          isActive &&
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="white" className="bi bi-check" viewBox="0 0 16 16">
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
          </svg>
        }
      </ProgressCicle>
    </ProgressWrapper >
  )
}

export default ProgressItem