export function toBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}

export const contactWhatsapp = (
    phoneNumber: string,
    rentDate: string,
    boxLink: string,
    jornada: string | null | undefined,
) => {
    return `
      https://api.whatsapp.com/send?phone=${phoneNumber}
      &text=Hola,%20quisiera%20saber%20si%20tienen%20disponibilidad%20en%20este%20Box%20para%20esta%20fecha%20${rentDate}
      %20${jornada ? `y%jornada%20${jornada}` : ''} 
      %0A%0ALink:%20${boxLink}
    `
}
