// Modules
import React from 'react'
import styled from 'styled-components'
import Progress from '../Progress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import history from '../../services/history'

//Icons
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { ICard } from './CardIT'
import Loading from '../Loading/Loading'

const CardWrapper = styled('div')`
    ${({ theme }) => `
    width: 400px;
    margin-top: ${theme?.spacing?.spc50};
    margin-bottom: ${theme?.spacing?.spc50};

    @media screen and (max-width:500px){
      width:88%;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
    }
  `}
`

const CardHeader = styled('div')`
    ${({ theme }) => `
    padding: ${theme?.spacing?.spc20};
    width: 100%;
    background-color: ${theme?.colors?.white};
    border-width: 2px;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    border: 0 solid ${theme?.colors?.borderInputs};
    border-bottom-width: 1px;
    box-shadow: -3px 15px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.15);
  `}
`

const CardContainer = styled('div')`
    ${({ theme }) => `
    padding: ${theme?.spacing?.spc20};
    width: 100%;
    background-color: ${theme?.colors?.white};
    border-width: 2px;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    box-shadow: -3px 15px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.15);
  `}
`

const Title = styled('div')`
    ${({ theme }) => `
    text-align: center;
    color: ${theme?.colors?.secondBase};
    font-size: ${theme?.fontSizes?.font18};
    font-weight: bold;
    font-family: raleway;
    margin-bottom: ${theme?.spacing?.spc25};
    margin-top: ${theme?.spacing?.spc16};
  `}
`

const Mark = styled('a')`
    ${({ theme }) => `
    color: ${theme?.colors?.base};
    font-family: raleway;
  `}
`

const ButtonsWrapper = styled('div')`
    ${({ theme }) => `
    width:100%;
    margin-top: ${theme?.spacing?.spc50};

    @media screen and (min-width:768px){
      margin-left:${theme?.spacing?.spc18};
    }
  `}
`

const BackButton = styled('button')`
    ${({ theme }) => `
    border: none;
    background: none;
    font-size: ${theme?.fontSizes?.font18};
    color: ${theme?.colors?.greyBase};
    font-weight: bold;
    cursor: pointer;
    padding: ${theme?.spacing?.spc10}; ${theme?.spacing?.spc20};
    text-decoration: underline;
    &:hover {
      color: ${theme?.colors?.secondBase};
    }
    @media screen and (max-width:600px){
      margin-right:${theme?.spacing?.spc20};
    }

  `}
`

const NextButton = styled('button')`
    ${({ theme }) => `
    border: none;
    background: ${theme?.colors?.base};
    font-size: ${theme?.fontSizes?.font18};
    color: ${theme?.colors?.white};
    font-weight: bold;
    float: right;
    cursor: pointer;
    padding: ${theme?.spacing?.spc10}; ${theme?.spacing?.spc20};
    border-radius: .25rem;
    &:hover {
      background: ${theme?.colors?.secondBaseHover};
      
    }
  `}
`

function Card({ mark, title, children, current, backLink, formRef, isLoading, onSubmit }: ICard) {
    return (
        <>
            <CardWrapper>
                <CardHeader>
                    <Title>
                        <Mark>{mark}</Mark> {title}
                    </Title>
                    <Progress current={current} />
                </CardHeader>
                <CardContainer>{children}</CardContainer>
                <ButtonsWrapper>
                    <BackButton onClick={() => history.push(backLink)}>
                        <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                    </BackButton>
                    <NextButton type='submit' form={formRef} onClick={onSubmit}>
                        Continuar <FontAwesomeIcon icon={faChevronRight} />
                    </NextButton>
                </ButtonsWrapper>
            </CardWrapper>
            {isLoading ? <Loading /> : null}
        </>
    )
}

export default Card
