// Modules
import React from 'react'
import Dropzone, { IExtra, IFileWithMeta } from 'react-dropzone-uploader'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Icons
import { faFileAlt, faFileImage } from '@fortawesome/free-solid-svg-icons'
import { DragAndDropIT } from './DragAndDropIT'

const WrapperDropzone = styled('div')`
    ${({ theme }) => `
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
  `}
`

const WrapperContent = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg{
      color: ${theme?.colors?.greyBase};
      font-size:${theme?.fontSizes?.font40};
      margin-top: ${theme?.spacing?.spc10};
      margin-bottom: ${theme?.spacing?.spc10};
    }
  `}
`
const TextContent = styled('p')`
    ${({ theme }) => `
    color: ${theme?.colors?.greyBase};
    font-size:${theme?.fontSizes?.font14};
    text-align: center;
    font-family: raleway-thin;
    margin: 0;
  `}
`

const BoldText = styled('p')`
    ${({ theme }) => `
    color: ${theme?.colors?.greyBase};
    font-size:${theme?.fontSizes?.font14};
    font-weight: bold;
    text-align: center;
    font-family: raleway;
  `}
`
interface FileUploadIT {
    file: File | null | undefined
    meta: any
}

const DragAndDropFiles = ({ text, textBold, currentFile, setCurrentFile, fileValidation, index }: DragAndDropIT) => {
    // specify upload params and url for your files
    const getUploadParams = () => {
        return { url: 'https://httpbin.org/post' }
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ file, meta }: FileUploadIT, status: string) => {
        if (status === 'done') {
            setCurrentFile([...currentFile, file], index)
        }
        if (status === 'removed') {
            const newFileArr = currentFile?.filter((item: any) => item?.name !== meta?.name)
            setCurrentFile(newFileArr, index)
        }
    }

    return (
        <WrapperDropzone>
            <BoldText>El tamaño máximo es 10 MB</BoldText>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                inputContent={(files: IFileWithMeta[], extra: IExtra) =>
                    extra.reject ? (
                        `Image, audio and video files only`
                    ) : (
                        <WrapperContent>
                            <FontAwesomeIcon icon={fileValidation === 'image' ? faFileImage : faFileAlt} />
                            <TextContent>{text}</TextContent>
                            <BoldText>{textBold}</BoldText>
                        </WrapperContent>
                    )
                }
                accept={fileValidation === 'image' ? 'image/*' : 'image/*,.docx,.pdf,.xlsx, .xls, .csv'}
                inputWithFilesContent={'Agregar otro'}
                maxFiles={3}
                styles={{
                    dropzone: {
                        width: '100%',
                        height: '6.5rem',
                        padding: 0,
                        margin: 0,
                        border: '2.5px dashed #ccc',
                        overflow: 'auto',
                    },
                    dropzoneActive: { borderColor: 'green' },
                }}
                SubmitButtonComponent={() => null}
            />
        </WrapperDropzone>
    )
}

export default DragAndDropFiles
