// Modules
import React from 'react'
import styled from 'styled-components'

//Icons
import ToallaDePapel from '../../../assets/image/svg/Materiales disponibles/toallapapel.svg'
import Vasito from '../../../assets/image/svg/Materiales disponibles/Vasito.svg'
import EjectorDesechable from '../../../assets/image/svg/Materiales disponibles/ejector desechable.svg'
import Jabon from '../../../assets/image/svg/Materiales disponibles/Jabon.svg'
import Guantes from '../../../assets/image/svg/Materiales disponibles/Guantes.svg'
import Pechera from '../../../assets/image/svg/Materiales disponibles/Pechera.svg'
import GorroDeschable from '../../../assets/image/svg/Materiales disponibles/Gorro desechable.svg'
import Mascarilla from '../../../assets/image/svg/Materiales disponibles/Mascarilla.svg'
import LamparaFotocurado from '../../../assets/image/svg/Materiales disponibles/Lampara fotocurado.svg'
import UltraSonido from '../../../assets/image/svg/Materiales disponibles/Ultra sonido.svg'
import LentesProteccion from '../../../assets/image/svg/Materiales disponibles/Lentes proteccion.svg'
import InstrumentalRotatorio from '../../../assets/image/svg/Materiales disponibles/Instrumental rotatorio.svg'

const MaterialImage = styled.img`
    ${({ theme }) => `
width:2.2rem;
height:2.2rem;
`}
`

const materialsList = [
    {
        title: 'Toalla de papel',
        icon: <MaterialImage src={ToallaDePapel} alt='ToallaDePapel' />,
    },
    {
        title: 'Vasito',
        icon: <MaterialImage src={Vasito} alt='Vasito' />,
    },
    {
        title: 'Ejector desechable',
        icon: <MaterialImage src={EjectorDesechable} alt='Ejector desechable' />,
    },
    {
        title: 'Jabón',
        icon: <MaterialImage src={Jabon} alt='Jabon' />,
    },
    {
        title: 'Guantes',
        icon: <MaterialImage src={Guantes} alt='Guantes' />,
    },
    {
        title: 'Pechera',
        icon: <MaterialImage src={Pechera} alt='Pechera' />,
    },
    {
        title: 'Gorro desechable',
        icon: <MaterialImage src={GorroDeschable} alt='Gorro deschable' />,
    },
    {
        title: 'Mascarilla',
        icon: <MaterialImage src={Mascarilla} alt='Mascarilla' />,
    },
    {
        title: 'Lámpara fotocurado',
        icon: <MaterialImage src={LamparaFotocurado} alt='Lámpara fotocurado' />,
    },
    {
        title: 'Ultrasonido',
        icon: <MaterialImage src={UltraSonido} alt='Ultrasonido' />,
    },
    {
        title: 'Lentes de protecctión',
        icon: <MaterialImage src={LentesProteccion} alt='Lentes de protecction' />,
    },
    {
        title: 'Instrumental rotatorio',
        icon: <MaterialImage src={InstrumentalRotatorio} alt='Instrumental Rotatorio' />,
    },
]

export { materialsList }
