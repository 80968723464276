// Modules
import { Route, Router, Switch } from 'react-router'
import history from '../services/history'

// Routes Register
import StartRegister from '../pages/Register'
import Completed from '../pages/Register/Completed/Completed'
import Address from '../pages/Register/Address/Address'
import BusinessInfo from '../pages/Register/BusinessInfo/BusinessInfo'
import DaysRent from '../pages/Register/DaysRent/DaysRent'
import MaterialsAvailable from '../pages/Register/MaterialsAvailable/MaterialsAvailable'
import PersonalData from '../pages/Register/PersonalData/PersonaData'
import ServicesAvailable from '../pages/Register/ServicesAvailable/ServicesAvailable'
import AddDocument from '../pages/Register/AddDocument/AddDocument'
import Summary from '../pages/Register/Summary/Summary'
import Home from '../pages'
import ClinicDetails from '../pages/ClinicDetails/ClinicDetails'
import TermsAndConditions from '../pages/TermsAndConditions'

const AppRouter = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path='/onboarding/business-info' component={BusinessInfo} />
                <Route exact path='/onboarding/completed' component={Completed} />
                <Route exact path='/onboarding/days-rent' component={DaysRent} />
                <Route exact path='/onboarding/materials-available' component={MaterialsAvailable} />
                <Route exact path='/onboarding/personal-data' component={PersonalData} />
                <Route exact path='/onboarding/services-available' component={ServicesAvailable} />
                <Route exact path='/onboarding/address' component={Address} />
                <Route exact path='/onboarding/add-document' component={AddDocument} />
                <Route exact path='/onboarding/summary' component={Summary} />
                <Route exact path='/onboarding' component={StartRegister} />
                <Route exact path='/box/details/:slug' component={ClinicDetails} />
                <Route exact path='/terminos-y-condiciones' component={TermsAndConditions} />
                <Route exact path='/' component={Home} />
                <Route path='*' component={Home} />
            </Switch>
        </Router>
    )
}

export default AppRouter
