import api from '../../axios'
import { InputRentIT, InputRentUpdateListIT } from './interface'

export default class RentService {
    async addRent(formData: InputRentIT) {
        const resp = await api.post('/rent', formData)
        const { data }: any = resp.data
        return data
    }

    async addRentList(rents: Array<InputRentIT>) {
        const dataInput = {
            rents,
        }
        const resp = await api.post('/rent/list', dataInput)
        const { data }: any = resp.data
        return data
    }

    async updateRent(_id: string, formData: InputRentUpdateListIT) {
        const resp = await api.put(`/user/${_id}`, formData)
        const { data }: any = resp.data
        return data
    }

    async updateRentList(formData: Array<InputRentUpdateListIT>) {
        const resp = await api.put('/rent', formData)
        const { data }: any = resp.data
        return data
    }
}
