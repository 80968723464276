// Modules
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// Components
import { FormContentWrapper, Card, DragAndDropFiles } from '../../../components'

//Icons
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

// Redux
import { toastDanger } from '../../../redux/actions/toast/action'
import { BoxIT } from './interface'
import { BoxService } from '../../../services'
import { SavingBox } from '../../../redux/actions/registerForms/action'

const BoxWrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 0.5px solid #e1e1e1;
    margin-top: ${theme?.spacing?.spc10};
    margin-bottom: ${theme?.spacing?.spc10};
  `}
`

const FormWrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    form{
      width: 100%;
    }
  `}
`

const FormHeader = styled('div')`
    ${({ theme }) => `
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid #e1e1e1;
    padding: ${theme?.spacing?.spc10};

    input{
      width: 100px;
      height: 1.5 rem;
      margin-left: ${theme?.spacing?.spc30};
      border: 1px solid ${theme?.colors?.greyLight};
      padding: ${theme?.spacing?.spc10};
      outline: none;
    }
  `}
`

const FormLabel = styled('div')`
    ${({ theme }) => `
    color: ${theme?.colors?.secondBase};
    font-family: raleway;
    font-size: ${theme?.fontSizes?.font16};
  `}
`

const FormFieldControl = styled('div')`
    ${({ theme }) => `
    width: 80%;
    display: flex;
    align-items: center;    
    margin-bottom: ${theme?.spacing?.spc30};
    input{
      width: 100px;
      height: 1.5 rem;
      margin-left: ${theme?.spacing?.spc30};
      border: 1px solid ${theme?.colors?.greyLight};
      padding: ${theme?.spacing?.spc10};
      outline: none;
    }
  `}
`

const InputField = styled('input')`
    ${({ theme }) => `
    width: 100%;
  `}
`

const SubeImagenesParagraph = styled('p')`
    ${({ theme }) => `
      color: ${theme?.colors?.base};
        font-family: raleway;
        font-size: ${theme?.fontSizes?.font16};
  `}
`

const AddBoxButton = styled('button')`
    ${({ theme }) => `
    border: none;
    background: ${theme?.colors?.secondBaseHover};
    font-size: ${theme?.fontSizes?.font16};
    color: ${theme?.colors?.white};
    width: 100%;
    cursor: pointer;
    font-family: raleway;
    padding: ${theme?.spacing?.spc10} ${theme?.spacing?.spc20};
    border-radius: .25rem;
    svg{
      margin-right: ${theme?.spacing?.spc10};
    }
    &:hover {
      background: ${theme?.colors?.base};
    }
  `}
`

const RemoveButton = styled('button')`
    ${({ theme }) => `
    border: none;
    margin-left:${theme?.spacing?.spc10};
    background: none;
    text-decoration: underline;
    font-size: ${theme?.fontSizes?.font16};
    color: ${theme?.colors?.secondBaseHover};
    cursor: pointer;
    font-family: raleway;
    padding: ${theme?.spacing?.spc10} ${theme?.spacing?.spc10};
    border-radius: .25rem;
    svg{
      margin-right: ${theme?.spacing?.spc10};
    }
    &:hover {
      color: ${theme?.colors?.base};
    }
  `}
`

const TitleBox = styled('span')`
    ${({ theme }) => `
    color: ${theme?.colors?.base};
    font-family: raleway;
    font-size: ${theme?.fontSizes?.font18};
  `}
`

const ImagePreview = styled('img')`
    ${({ theme }) => `
    max-width: 150px;
    height: auto;
    border-radius: ${theme?.spacing?.spc10};
  `}
`

function BusinessInfo() {
    // Reducers
    const {
        registerForms: {
            boxes: { data },
            boxAddress: address,
        },
    }: any = useSelector(state => state)

    // States
    const [loading, setLoading] = useState(false)
    const [boxes, setBoxes] = useState([
        {
            boxSize: 0,
            files: [],
            edited: false,
        },
    ])
    const [clinicName, setClinicName] = useState('')
    const [editMode, setEditMode] = useState(false)

    // Dispatch
    const dispatch = useDispatch()

    // Routes
    const history = useHistory()
    const { search } = useLocation()
    const summary = search?.split('=')[1]

    // Use Effects
    useEffect(() => {
        if (data?.length > 0) {
            const loadedBoxes = data?.map((item: any) => {
                const newBox = {
                    boxName: item?.name,
                    boxSize: item?.boxSize,
                    files: item?.images,
                    edited: true,
                }
                return newBox
            })
            setBoxes(loadedBoxes)
            setEditMode(true)
        }
    }, [data])

    // Methods
    const handleRemoveItem = (position: number) => {
        if (boxes.length > 1) {
            const newBoxes = boxes?.filter((item: any, index: number) => index !== position)
            setBoxes(newBoxes)
        } else {
            dispatch(toastDanger('Debes agregar al menos un box!'))
        }
    }

    const setCurrentFile = (value: File, position: number) => {
        const newArr = boxes.map((item: any, index: number) => {
            if (index === position) {
                item.files = value
            }
            return item
        })
        setBoxes(newArr)
    }

    const handleAddBox = () => {
        setBoxes([
            ...boxes,
            {
                boxSize: 0,
                files: [],
                edited: false,
            },
        ])
    }

    const handleChangeBoxSize = (value: any, position: number) => {
        const newArr = boxes.map((item: any, index: number) => {
            if (index === position) {
                item.boxSize = value
            }
            return item
        })
        setBoxes(newArr)
    }

    const isValid = (values: Array<BoxIT>, name: string): boolean => {
        let fileIsValid: number = 0
        let metersIsValid: number = 0
        let nameIsValid: number = 0

        values.forEach((item: BoxIT) => {
            if (item?.files?.length === 0) {
                fileIsValid += 1
            }
            if (item.boxSize <= 0) {
                metersIsValid += 1
            }
        })

        if (name?.length <= 3) {
            nameIsValid += 1
        }

        if (nameIsValid > 0) {
            dispatch(toastDanger('Debes poner al menos el nombre de la clínica !!'))
        }

        if (fileIsValid > 0 && !editMode) {
            dispatch(toastDanger('Debes poner al menos una imagen por box !'))
        }
        if (metersIsValid > 0) {
            dispatch(toastDanger('Debes ingresar un valor mayor a 0!'))
        }

        if (!nameIsValid && !fileIsValid && !metersIsValid) {
            return true
        } else {
            return false
        }
    }

    const createBox = async (boxes: Array<BoxIT>, name: string) => {
        try {
            setLoading(true)
            if (editMode) {
                if (summary) {
                    history.push('/onboarding/summary')
                } else {
                    history.push(`/onboarding/add-document`)
                }
            } else {
                const boxService = new BoxService()
                const addressToSave: string = JSON.stringify(address)
                const result = await boxService.create(boxes, addressToSave, name)
                dispatch(SavingBox(result))
                setLoading(false)
                if (summary) {
                    history.push('/onboarding/summary')
                } else {
                    history.push(`/onboarding/add-document`)
                }
            }
        } catch (error) {
            dispatch(toastDanger('Error, inténtelo de nuevo más tarde!'))
            setLoading(false)
        }
    }

    return (
        <FormContentWrapper>
            <Card
                mark={'Completa'}
                title={'los datos solicitados'}
                current={2}
                backLink={'/onboarding/address'}
                isLoading={loading}
                onSubmit={() => {
                    if (isValid(boxes, clinicName)) {
                        try {
                            createBox(boxes, clinicName)
                        } catch (error) {
                            dispatch(toastDanger('Error al guardar!'))
                        }
                    }
                }}
            >
                <FormWrapper>
                    <FormHeader>
                        <FormLabel>¿Cuál es el nombre de la Clínica?</FormLabel>
                        <InputField
                            value={clinicName}
                            onChange={(e: any) => setClinicName(e?.target?.value)}
                            name='clinicName'
                            type='text'
                        />
                    </FormHeader>
                    {boxes?.map((item: any, index: number) => {
                        return (
                            <BoxWrapper key={index}>
                                <FormFieldControl>
                                    <TitleBox>Box {index + 1}</TitleBox>
                                    <RemoveButton type='button' onClick={() => handleRemoveItem(index)}>
                                        <FontAwesomeIcon icon={faTimesCircle} />
                                        Borrar
                                    </RemoveButton>
                                </FormFieldControl>
                                <FormFieldControl>
                                    <FormLabel>¿Cuántos m2 tienen el Box?</FormLabel>
                                    <InputField
                                        value={item?.boxSize}
                                        onChange={(e: any) => handleChangeBoxSize(e?.target?.value, index)}
                                        name='boxSize'
                                        type='number'
                                        min='1'
                                    />
                                </FormFieldControl>
                                <FormFieldControl
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                                >
                                    <SubeImagenesParagraph>Sube imágenes de tu Box</SubeImagenesParagraph>
                                    {!item?.edited ? (
                                        <DragAndDropFiles
                                            key={index}
                                            fileValidation={'image'}
                                            currentFile={item?.files}
                                            setCurrentFile={setCurrentFile}
                                            text={'Arrasta y suelta tus archivos (.jpg, .png, .gif)'}
                                            textBold={'Cargar archivos'}
                                            index={index}
                                        />
                                    ) : (
                                        item?.files?.map((imageItem: any, index: number) => {
                                            return <ImagePreview key={index} src={imageItem?.url} alt={item?.name} />
                                        })
                                    )}
                                </FormFieldControl>
                            </BoxWrapper>
                        )
                    })}
                    <AddBoxButton type='button' onClick={() => handleAddBox()}>
                        <FontAwesomeIcon icon={faPlus} />
                        Agregar otro box
                    </AddBoxButton>
                </FormWrapper>
            </Card>
        </FormContentWrapper>
    )
}

export default BusinessInfo
