// Modules
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

// Components
import { FormContentWrapper, Card, DragAndDropFiles } from '../../../components'
import BoxService from '../../../services/box/box'
import { useDispatch, useSelector } from 'react-redux'

// Routes
import history from '../../../services/history'
import { SavingBox } from '../../../redux/actions/registerForms/action'
import { toastDanger } from '../../../redux/actions/toast/action'

const BoxDocument = styled('div')`
    ${({ theme }) => `
    width: 100%;
  `}
`

const FormFieldControl = styled('div')`
    ${({ theme }) => `
    width: 80%;
    display: flex;
    align-items: center; 
    margin-left: 10%;
    margin-bottom: ${theme?.spacing?.spc30};
  `}
`

const TextLabel = styled('div')`
    ${({ theme }) => `
    color: ${theme?.colors?.secondBase};
    font-family: raleway;
    font-size: ${theme?.fontSizes?.font16};
  `}
`

function AddDocument() {
    // Reference
    const formRef = 'addDocument-form'
    const { search } = useLocation()
    const summary = search?.split('=')[1]

    // States
    const [currentFile, setCurrentFile] = useState([])
    const [loading, setLoading] = useState(false)

    // Reducers
    const {
        registerForms: {
            boxes: { data },
        },
    }: any = useSelector(state => state)

    // Dispatch
    const dispatch = useDispatch()

    // Methods
    const handleSubmit = async (_id: string, files: Array<File>) => {
        if (files.length > 0) {
            try {
                setLoading(true)
                const boxService = new BoxService()
                const result = await boxService.addDocument(_id, files)
                dispatch(SavingBox(result))
                setLoading(false)
                if (summary) {
                    history.push('/onboarding/summary')
                } else {
                    history.push('/onboarding/materials-available')
                }
            } catch (error) {
                dispatch(toastDanger('Error, inténtelo de nuevo más tarde!'))
                setLoading(false)
            }
        } else {
            if (summary) {
                history.push('/onboarding/summary')
            } else {
                history.push('/onboarding/materials-available')
            }
        }
    }

    return (
        <FormContentWrapper>
            <Card
                mark={''}
                title={'Resolución Sanitaria'}
                current={3}
                backLink={'/onboarding/business-info'}
                formRef={formRef}
                onSubmit={() => handleSubmit(data[0]?._id, currentFile)}
                isLoading={loading}
            >
                <BoxDocument>
                    <FormFieldControl>
                        <TextLabel>
                            Por favor adjunta aquí la autorización de instalación y funcionamento del SEREMI (resolución
                            sanitaria).
                        </TextLabel>
                    </FormFieldControl>
                    <FormFieldControl>
                        <DragAndDropFiles
                            fileValidation={'document'}
                            currentFile={currentFile}
                            setCurrentFile={setCurrentFile}
                            text={'Arrasta y suelta tus archivos (.pdf, .docx)'}
                            textBold={'Cargar archivos'}
                        />
                    </FormFieldControl>
                </BoxDocument>
            </Card>
        </FormContentWrapper>
    )
}
export default AddDocument
