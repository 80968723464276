// Modules
import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Components
import { FormContentWrapper, Card } from '../../../components'

//Icons
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

// Routes
import history from '../../../services/history'
import { ResetRegisterForm } from '../../../redux/actions/registerForms/action'

const SummaryWrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center; 
    flex-direction: column;
    margin-bottom: ${theme?.spacing?.spc10};
  `}
`

const SummaryItem = styled('div')`
    ${({ theme }) => `
    padding: 0 ${theme?.spacing?.spc20};
    width: 90%;
    display: flex;
    align-items: flex-start; 
    flex-direction: column;
    border-bottom: 0.5px solid ${theme?.colors?.greyLight};
    margin-bottom: ${theme?.spacing?.spc10};
  `}
`

const ItemTitle = styled('p')`
    ${({ theme }) => `
    color: ${theme?.colors?.secondBase};
    font-size: ${theme?.fontSizes?.font14};
    font-family: raleway;
    font-weight: bold;
  `}
`

const ItemEdit = styled('span')`
    ${({ theme }) => `
    color: ${theme?.colors?.base};
    font-size: ${theme?.fontSizes?.font14};
    font-family: raleway;
    font-weight: bold;
    margin-left: ${theme?.spacing?.spc10};
    cursor: pointer;
    text-decoration: underline;

    svg{
      margin-left: ${theme?.spacing?.spc10};
    }
  `}
`

const ItemData = styled('div')`
    ${({ theme }) => `
    color: ${theme?.colors?.black};
    font-size: ${theme?.fontSizes?.font14};
    font-family: raleway;
    font-weight: bold;
    margin-bottom: ${theme?.spacing?.spc10};
  `}
`
const BoxList = styled('ul')`
    ${({ theme }) => `
    list-style:none;
    margin: 0;
    padding: 0;
  `}
`

const BoxListItem = styled('li')`
    ${({ theme }) => `
    color: ${theme?.colors?.black};
    font-size: ${theme?.fontSizes?.font14};
    font-family: raleway;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        margin-right: ${theme?.spacing?.spc10};
        border-radius: ${theme?.spacing?.spc4};
    }
  `}
`
interface ItemEditCompIT {
    routePath: string
}

function ItemEditComponent({ routePath }: ItemEditCompIT) {
    // Routes
    const history = useHistory()

    return (
        <ItemEdit onClick={() => history.push(routePath)}>
            Editar
            <FontAwesomeIcon icon={faPencilAlt} />
        </ItemEdit>
    )
}

function Summary() {
    // Reference
    const formRef = 'addDocument-form'

    // Dispatch
    const dispatch = useDispatch()

    // Reducers
    const {
        registerForms: {
            boxes: { data },
            boxAddress: address,
            personalData,
        },
    }: any = useSelector(state => state)

    return (
        <FormContentWrapper>
            <Card
                mark={''}
                title={'Confirmar información'}
                current={8}
                backLink={'/onboarding/personal-data'}
                formRef={formRef}
                onSubmit={() => {
                    localStorage.removeItem('global_state')
                    dispatch(ResetRegisterForm())
                    history.push('/onboarding/completed')
                }}
            >
                <SummaryWrapper>
                    <SummaryItem>
                        <ItemTitle>
                            Dirección:
                            <ItemEditComponent routePath={'/onboarding/address?summary=true'} />
                        </ItemTitle>
                        <ItemData>{address?.name}</ItemData>
                    </SummaryItem>
                    <SummaryItem>
                        <ItemTitle>
                            Boxes info:
                            <ItemEditComponent routePath={'/onboarding/business-info?summary=true'} />
                        </ItemTitle>
                        <ItemData>
                            <BoxList>
                                {data?.map((item: any, index: number) => {
                                    return (
                                        <BoxListItem key={index}>
                                            <img src={item?.images[0]?.url} alt={item?.name} height='40' />
                                            {item?.name + ' - ' + item?.boxSize + 'm2'}
                                        </BoxListItem>
                                    )
                                })}
                            </BoxList>
                        </ItemData>
                    </SummaryItem>
                    <SummaryItem>
                        <ItemTitle>
                            Resolución Sanitaria:
                            <ItemEditComponent routePath={'/onboarding/add-document?summary=true'} />
                        </ItemTitle>
                        <ItemData>
                            {data?.map((item: any) => {
                                return item?.authorizationDocs?.length > 0 ? 'Sí' : 'No'
                            })}
                        </ItemData>
                    </SummaryItem>
                    <SummaryItem>
                        <ItemTitle>
                            Materiales:
                            <ItemEditComponent routePath={'/onboarding/materials-available?summary=true'} />
                        </ItemTitle>
                        <ItemData>{data?.map((item: any) => item?.materials.join(' - '))}</ItemData>
                    </SummaryItem>
                    <SummaryItem>
                        <ItemTitle>
                            Servicios disponibles:
                            <ItemEditComponent routePath={'/onboarding/services-available?summary=true'} />
                        </ItemTitle>
                        <ItemData>{data?.map((item: any) => item?.services.join(' - '))}</ItemData>
                    </SummaryItem>
                    <SummaryItem>
                        <ItemTitle>
                            Dias arrendados:
                            <ItemEditComponent routePath={'/onboarding/days-rent?summary=true'} />
                        </ItemTitle>
                    </SummaryItem>
                    <SummaryItem>
                        <ItemTitle>
                            Datos ingresados:
                            <ItemEditComponent routePath={'/onboarding/personal-data?summary=true'} />
                        </ItemTitle>
                        <ItemData>{`${personalData?.name} - ${personalData?.phone} - ${personalData?.email}`}</ItemData>
                    </SummaryItem>
                </SummaryWrapper>
            </Card>
        </FormContentWrapper>
    )
}
export default Summary
