// Modules
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Components
import Card from '../../../components/Card'

// Images
import { FormContentWrapper } from '../../../components'

// Data
import { materialsList } from './MaterialsAvailable.data'

// Routes
// import history from '../../services/history'
import { toastDanger } from '../../../redux/actions/toast/action'
import { MaterialAvailableIT } from '../../../redux/reducers/registerForms/interface'
import { useHistory } from 'react-router'
import { BoxService } from '../../../services'
import { UpdateMaterialsIT } from './interface'
import { SavingBox } from '../../../redux/actions/registerForms/action'
import { useLocation } from 'react-router-dom'

const MaterialWrapper = styled('div')`
    ${({ theme }) => `
    width:100%;
    height:18rem;
    display: flex;
    align-items:center;
    justify-content:center;
    border-radius: ${theme?.spacing?.spc6};
    flex-wrap: wrap;
    

    @media screen and (max-width:450px){
      height:auto;
    }
  `}
`

const FormFieldControl = styled('div')`
    ${({ theme }) => `
    width: 80%;
    display: flex;
    align-items: center; 
    margin-left: 10%;
    margin-bottom: ${theme?.spacing?.spc30};

    input{
      width: 100px;
      height: 1.5 rem;
      margin-left: ${theme?.spacing?.spc30};
      border: 1px solid ${theme?.colors?.greyLight};
      padding: ${theme?.spacing?.spc10};
      outline: none;
    }
  `}
`

interface Props {
    active: boolean
}

const MaterialItem = styled('div')<Props>`
    ${({ theme, active }) => `
    height: 4.2rem;
    width: 5rem;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border-radius: ${theme?.spacing?.spc6};
    padding: ${theme?.spacing?.spc4};
    margin-left:${theme?.spacing?.spc8};
    margin-top:${theme?.spacing?.spc14};
    border: 1px solid #e1e1e1;
    cursor:pointer;

    ${
        active
            ? `background-color:${theme?.colors?.base};
      color:${theme?.colors?.white};
      p{
        color:${theme?.colors?.white};
      }`
            : ``
    }

    &:hover{
      background-color:${theme?.colors?.base};
      color:${theme?.colors?.white};
      p{
        color:${theme?.colors?.white};
      }
    }
  
  `}
`

const MaterialTitle = styled.p`
    ${({ theme }) => `
  color:${theme?.colors?.secondBase};
  font-size:${theme?.fontSizes?.font8};
  font-weight:bold;
  font-family: raleway;
  text-align:center;
`}
`
const TextLabel = styled('div')`
    ${({ theme }) => `
    color: ${theme?.colors?.secondBase};
    font-family: raleway;
    font-size: ${theme?.fontSizes?.font16};
  `}
`

const IconWrapper = styled.div`
    ${({ theme }) => `
   width:auto;
   height:auto;
   display:flex;
   align-items:center;
   justify-content:center;

   svg{
     font-size:${theme?.fontSizes?.font27};
     color${theme?.colors?.secondBase};
   }
`}
`

interface MaterialListIT {
    text: string
    active: boolean
}

function MaterialsAvailable() {
    // States
    const [materialList, setMaterialList] = useState([
        { text: 'Toalla de papel', active: false },
        { text: 'Vasito', active: false },
        { text: 'Ejector desechable', active: false },
        { text: 'Jabón', active: false },
        { text: 'Guantes', active: false },
        { text: 'Pechera', active: false },
        { text: 'Gorro desechable', active: false },
        { text: 'Mascarilla', active: false },
        { text: 'Lámpara fotocurado', active: false },
        { text: 'Ultrasonido', active: false },
        { text: 'Lentes de protecctión', active: false },
        { text: 'Instrumental rotatorio', active: false },
    ])
    const [isLoading, setIsLoading] = useState(false)
    const [loadInfo, setLoadInfo] = useState(true)

    // Reducers
    const {
        registerForms: {
            boxes: { data },
        },
    }: any = useSelector(state => state)

    // Dispatch
    const dispatch = useDispatch()

    // Routes
    const history = useHistory()
    const { search } = useLocation()
    const summary = search?.split('=')[1]

    // UseEffects
    useEffect(() => {
        if (data[0] && loadInfo) {
            let newMaterialList: React.SetStateAction<{ text: string; active: boolean }[]> | any = []
            materialList?.forEach(materialItem => {
                let flag: boolean = false
                data[0]?.materials?.forEach((loadedItem: any) => {
                    if (materialItem.text === loadedItem) {
                        flag = true
                    }
                })
                if (flag) {
                    newMaterialList.push({ text: materialItem.text, active: true })
                } else {
                    newMaterialList.push(materialItem)
                }
            })
            setMaterialList(newMaterialList)
            setLoadInfo(false)
        }
    }, [data, materialList, loadInfo])

    // Methods
    const handleAddMaterial = (keyNumber: number) => {
        const newState = materialList.map((item: MaterialAvailableIT, index: number) => {
            if (keyNumber === index) {
                item.active = !item?.active
            }
            return item
        })
        setMaterialList(newState)
    }

    const isValid = (list: Array<MaterialListIT>): boolean => {
        let atLeastOne: boolean = false

        list?.forEach((item: MaterialListIT) => {
            if (item?.active) {
                atLeastOne = true
            }
        })
        return atLeastOne
    }

    const updateMaterials = async (data: any, materials: Array<string>) => {
        try {
            setIsLoading(true)
            const boxService = new BoxService()
            const saveData: Array<UpdateMaterialsIT> = data.map((item: any) => {
                const newItem = { id: item?._id, boxName: item?.name, boxSize: item?.boxSize, materials }
                return newItem
            })
            const resp = await boxService.updateMaterials(saveData)
            dispatch(SavingBox(resp))
            if (summary) {
                history.push('/onboarding/summary')
            } else {
                history.push('/onboarding/services-available')
            }
            setIsLoading(false)
        } catch (error) {
            dispatch(toastDanger('Error, inténtelo de nuevo más tarde!'))
            setIsLoading(false)
        }
    }

    return (
        <FormContentWrapper>
            <Card
                mark={'¿Qué materiales'}
                title={'estan disponibles en el box?'}
                current={4}
                isLoading={isLoading}
                backLink={'/onboarding/add-document'}
                onSubmit={() => {
                    if (isValid(materialList) && data) {
                        const arrMarterials: Array<string> = []
                        materialList.forEach((item: MaterialListIT) => {
                            if (item.active) arrMarterials.push(item.text)
                        })
                        updateMaterials(data, arrMarterials)
                    } else {
                        dispatch(toastDanger('Debes seleccionar al menos un material!'))
                    }
                }}
            >
                <FormFieldControl>
                    <TextLabel>
                        Recuerda que estos materiales deben estar incluidos en el valor de arriendo de tu box.
                    </TextLabel>
                </FormFieldControl>
                <MaterialWrapper>
                    {materialsList?.map((item, index: number) => {
                        return (
                            <MaterialItem
                                key={item?.title}
                                onClick={() => handleAddMaterial(index)}
                                active={materialList[index]?.active}
                            >
                                <IconWrapper>{item?.icon}</IconWrapper>
                                <MaterialTitle>{item?.title}</MaterialTitle>
                            </MaterialItem>
                        )
                    })}
                </MaterialWrapper>
            </Card>
        </FormContentWrapper>
    )
}

export default MaterialsAvailable
