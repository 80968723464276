// Modules
import React, { useState, useEffect } from 'react'
// Components
import Card from '../../../components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

// Images
import styled from 'styled-components'
import { FormContentWrapper } from '../../../components'
import { faPlus, faClock, faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

// Routes
import history from '../../../services/history'
import { toastDanger } from '../../../redux/actions/toast/action'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import RentService from '../../../services/rent/rent'
import { SavingRents } from '../../../redux/actions/registerForms/action'
import { InputRentIT } from './interface'

const InfoContainer = styled('div')`
    ${({ theme }) => `
    width:100%;
    height:auto;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
  `}
`
const ContentWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top:${theme?.spacing?.spc10};

        @media screen and (max-width:700px){
            margin-bottom:${theme?.spacing?.spc10};
            flex-direction column;
        }
    `}
`

const OptionBox = styled('option')`
    ${({ theme }) => `
  color:${theme?.colors?.white};
  font-family: raleway;
  font-weight: bold;
  font-size:${theme?.fontSizes?.font12};
`}
`
interface Props {
    active: boolean
}

const OptionTime = styled('button')<Props>`
    ${({ theme, active }) => `
    width:2.5rem;
    height:1.5rem;
    display:flex;
    align-items:center;
    justify-content:center;
    border:0.75px solid ${theme?.colors?.base};
    border-radius:${theme?.spacing?.spc6};
    margin-right:${theme?.spacing?.spc4};
    background: ${active ? theme?.colors?.base : theme?.colors?.white};

    svg{
       font-size:${theme?.fontSizes?.font10};
    }
  `}
`

const BoxPick = styled('select')`
    ${({ theme }) => `
        height:2rem;
        border:0px;
        width: 100%;
        background:${theme?.colors?.base};
        border-radius:${theme?.spacing?.spc4};
        margin-right:${theme?.spacing?.spc4};
        color:${theme?.colors?.white};
        font-size:${theme?.fontSizes?.font18};
        font-family: raleway;
        font-weight: bold;
        cursor:pointer;

    @media screen and (max-width:700px){
        margin-bottom:${theme?.spacing?.spc10};
    }
    `}
`

const DayPickWrapper = styled('div')`
    ${({ theme }) => `
        height:2rem;
        border:0px;
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        margin-right:${theme?.spacing?.spc4};
        color:${theme?.colors?.white};

        @media screen and (max-width:700px){
            margin-bottom:${theme?.spacing?.spc10};
        }
    `}
`

interface PropsDayPicker {
    isActive: boolean
}

const DayPick = styled('span')<PropsDayPicker>`
    ${({ theme, isActive }) => `
        height:2rem;
        width: 100%;
        background:${theme?.colors?.white};
        border-radius:${theme?.spacing?.spc4};
        margin-right:${theme?.spacing?.spc4};
        padding: ${theme?.spacing?.spc0} ${theme?.spacing?.spc2};
        color:${theme?.colors?.base};
        border: 1px solid ${theme?.colors?.base};
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:${theme?.fontSizes?.font18};
        font-family: raleway;
        font-weight: bold;
        cursor:pointer;

        ${isActive ? `background:${theme?.colors?.base} !important; color: ${theme?.colors?.white};` : ''}
    `}
`

const TextLabel = styled.span`
    ${({ theme }) => `
        color:${theme?.colors?.secondBase};
        font-size:${theme?.fontSizes?.font18};
        font-family:raleway;
  `}
`

const OptionWrapper = styled.span`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;

    svg{
        font-size:${theme?.fontSizes?.font14};
    }

    @media screen and (max-width:700px){
      margin-bottom:${theme?.spacing?.spc10};
    }
  `}
`

const ValueInput = styled('input')`
    ${({ theme }) => `
    height:2rem;
    border:1px solid ${theme?.colors?.greyLight};
    border-radius:${theme?.spacing?.spc6};
    font-size:${theme?.fontSizes?.font16};
    margin-left:${theme?.spacing?.spc6};
  `}
`

const AddWrapper = styled('div')`
    ${({ theme }) => `
        width:100%;
        height:auto;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-top:${theme?.spacing?.spc10};
    `}
`

const AddScheduleButton = styled('button')`
    ${({ theme }) => `
        border: none;
        background: ${theme?.colors?.secondBaseHover};
        font-size: ${theme?.fontSizes?.font16};
        color: ${theme?.colors?.white};
        width: 100%;
        cursor: pointer;
        font-family: raleway;
        padding: ${theme?.spacing?.spc10} ${theme?.spacing?.spc20};
        border-radius: .25rem;
        svg{
        margin-right: ${theme?.spacing?.spc10};
        }
        &:hover {
        background: ${theme?.colors?.base};
        }
  `}
`

const RentsWrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-top: 0.5px solid #e1e1e1;
    padding-top: ${theme?.spacing?.spc25};
    margin-top: ${theme?.spacing?.spc20};
    margin-bottom: ${theme?.spacing?.spc20};
`}
`

const RentItem = styled('div')`
    ${({ theme }) => `
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin-top: ${theme?.spacing?.spc10};
        background: ${theme?.colors?.base};
        padding: ${theme?.spacing?.spc6};
        color: ${theme?.colors?.white};
        margin-left: ${theme?.spacing?.spc6};

        svg {
        cursor: pointer;
        margin-left: ${theme?.spacing?.spc6};
        }
    `}
`
const RentItemText = styled('span')`
    ${({ theme }) => `
        padding: ${theme?.spacing?.spc4};
        color: ${theme?.colors?.white};
        font-family: raleway;
        font-size:${theme?.fontSizes?.font16};
  `}
`

function DaysRent() {
    // States
    const [schedules, setSchedules] = useState([])
    const [selBox, setSelBox] = useState('')
    const [selValue, setSelValue] = useState(0)

    // States Arr
    const [selTime, setSelTime] = useState([
        { value: 'AM', active: false },
        { value: 'PM', active: false },
    ])
    const [days, setDays] = useState([
        { text: 'L', value: 'Lunes', active: false },
        { text: 'M', value: 'Martes', active: false },
        { text: 'M', value: 'Miércoles', active: false },
        { text: 'J', value: 'Jueves', active: false },
        { text: 'V', value: 'Viernes', active: false },
        { text: 'S', value: 'Sábado', active: false },
        { text: 'D', value: 'Domingo', active: false },
    ])

    // States control
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)

    // Routes
    const { search } = useLocation()
    const summary = search?.split('=')[1]

    // Dispatch
    const dispatch = useDispatch()

    // Reducers
    const {
        registerForms: {
            rentInfo: { data },
            boxes: { data: boxesData },
        },
    }: any = useSelector(state => state)

    // Use Effects
    useEffect(() => {
        if (data?.length > 0) {
            const loadedRents = data?.map((item: any) => {
                const newRent = {
                    _id: item?._id,
                    boxName: 'name here',
                    day: item?.day,
                    time: item?.period,
                    value: item?.price,
                    indexKey: item.indexKey,
                }
                return newRent
            })
            setSchedules(loadedRents)
            setEditMode(true)
        }
    }, [data])

    // Methods
    const isValidToAdd = (
        box: any,
        choosedDays: any,
        chossedTimes: any,
        choosedValue: any,
        schedulesList: any,
        newArrSchedules: any,
    ) => {
        let isValidated = true
        if (!box || box === '' || box?.lenght === 0) {
            dispatch(toastDanger('Debes seleccionar al menos un box!'))
            isValidated = false
        }

        let isDaySelected = false
        choosedDays.forEach((chooseDay: any) => {
            if (chooseDay?.active) {
                isDaySelected = true
            }
        })

        if (!isDaySelected) {
            dispatch(toastDanger('Debes seleccionar al menos un dia!'))
            isValidated = false
        }

        let isTimeSelected = false
        chossedTimes.forEach((choosedTime: any) => {
            if (choosedTime?.active) {
                isTimeSelected = true
            }
        })
        if (!isTimeSelected) {
            dispatch(toastDanger('Debes seleccionar al menos una jornada!'))
            isValidated = false
        }
        if (choosedValue <= 0) {
            dispatch(toastDanger('Debes seleccionar al menos un valor!'))
            isValidated = false
        }

        console.log(schedulesList, newArrSchedules)
        let itemAlreadyExists: boolean = false
        schedulesList?.forEach((item: any) => {
            newArrSchedules?.forEach((elem: any) => {
                if (elem?.indexKey === item?.indexKey) {
                    isValidated = false
                    itemAlreadyExists = true
                }
            })
        })
        if (itemAlreadyExists) {
            dispatch(toastDanger('Ese artículo ya existe!'))
            isValidated = false
        }

        return isValidated
    }

    const handleChooseDay = (index: number) => {
        const newDays = days.map((day, indexDay) => {
            if (indexDay === index) {
                return {
                    text: day.text,
                    value: day.value,
                    active: !day.active,
                }
            }
            return day
        })
        setDays(newDays)
    }

    const handleChoosePeriod = (index: number) => {
        const newPeriods = selTime.map((period, indexPeriod) => {
            if (indexPeriod === index) {
                return {
                    value: period.value,
                    active: !period.active,
                }
            }
            return period
        })
        setSelTime(newPeriods)
    }

    const handleAddSchedule = (boxInfo: any, daysArr: any, times: any, value: any) => {
        let arrSchedules: any = []
        daysArr.forEach((day: any) => {
            if (day.active) {
                times.forEach((period: any) => {
                    if (period.active) {
                        const box = JSON.parse(boxInfo)
                        const indexKey = box?.name + day.value + period?.value
                        arrSchedules.push({
                            _id: box?._id,
                            boxName: box?.name,
                            day: day?.value,
                            time: period?.value,
                            value,
                            indexKey,
                        })
                    }
                })
            }
        })
        if (isValidToAdd(boxInfo, days, times, value, schedules, arrSchedules)) {
            const newArr: any = schedules.concat(arrSchedules)
            setSchedules(newArr)
            // Reset States
            setSelBox('')
            setSelValue(0)
            setSelTime([
                { value: 'AM', active: false },
                { value: 'PM', active: false },
            ])
            setDays([
                { text: 'L', value: 'Lunes', active: false },
                { text: 'M', value: 'Martes', active: false },
                { text: 'M', value: 'Miércoles', active: false },
                { text: 'J', value: 'Jueves', active: false },
                { text: 'V', value: 'Viernes', active: false },
                { text: 'S', value: 'Sábado', active: false },
                { text: 'D', value: 'Domingo', active: false },
            ])
        }
    }

    const handleRemoveRent = (position: number) => {
        const newSchedules = schedules?.filter((item: any, index: number) => index !== position)
        setSchedules(newSchedules)
    }

    const handleSubmit = async (goBack: any, schedulesArr: any) => {
        if (schedules.length > 0) {
            try {
                setLoading(true)
                if (editMode) {
                    if (goBack) {
                        history.push('/onboarding/summary')
                    } else {
                        history.push('/onboarding/personal-data')
                    }
                } else {
                    const rentToSave: Array<InputRentIT> = schedulesArr?.map((item: any) => {
                        const rentEntity: InputRentIT = {
                            boxId: item?._id,
                            price: parseInt(item?.value),
                            day: item?.day,
                            period: item?.time,
                        }
                        return rentEntity
                    })

                    const rentService = new RentService()
                    const result = await rentService.addRentList(rentToSave)
                    dispatch(SavingRents(result))
                    setLoading(false)
                    if (summary) {
                        history.push('/onboarding/summary')
                    } else {
                        history.push('/onboarding/personal-data')
                    }
                }
            } catch (error) {
                dispatch(toastDanger('Error, inténtelo de nuevo más tarde!'))
                setLoading(false)
            }
        } else {
            dispatch(toastDanger('Debe seleccionar al menos un día para arrendar tu box'))
        }
    }

    return (
        <FormContentWrapper>
            <Card
                mark={'¿Qué diás te'}
                title={'gustaría arrendar tu box?'}
                current={6}
                backLink={'/onboarding/services-available'}
                onSubmit={() => handleSubmit(summary, schedules)}
                isLoading={loading}
            >
                <InfoContainer>
                    <ContentWrapper>
                        <BoxPick
                            placeholder='Seleccione lo box'
                            value={selBox}
                            onChange={(e: any) => setSelBox(e.target.value)}
                        >
                            <OptionBox value='' selected disabled hidden>
                                Elija el box...
                            </OptionBox>
                            {boxesData?.map((box: any, index: number) => {
                                return (
                                    <OptionBox key={index} value={JSON.stringify(box)}>
                                        {box?.name}
                                    </OptionBox>
                                )
                            })}
                        </BoxPick>
                    </ContentWrapper>
                    <ContentWrapper>
                        <DayPickWrapper>
                            {days?.map((day, index: number) => {
                                return (
                                    <DayPick isActive={day.active} key={index} onClick={() => handleChooseDay(index)}>
                                        {day?.text}
                                    </DayPick>
                                )
                            })}
                        </DayPickWrapper>
                    </ContentWrapper>

                    <ContentWrapper style={{ marginTop: '10px' }}>
                        <OptionWrapper>
                            <TextLabel style={{ marginRight: '1.2rem' }}>Jornada:</TextLabel>
                            {selTime.map((period, index: number) => {
                                return (
                                    <OptionTime
                                        onClick={() => handleChoosePeriod(index)}
                                        key={index}
                                        style={{ cursor: 'pointer' }}
                                        active={period.active}
                                    >
                                        <FontAwesomeIcon icon={faClock} />
                                        <TextLabel>{period.value}</TextLabel>
                                    </OptionTime>
                                )
                            })}
                        </OptionWrapper>
                    </ContentWrapper>
                    <ContentWrapper style={{ marginBottom: '10px' }}>
                        <OptionWrapper>
                            <TextLabel>Valor: </TextLabel>
                            <Tooltip title='Valor + IVA'>
                                <IconButton>
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </IconButton>
                            </Tooltip>
                            <ValueInput
                                style={{ marginLeft: '12px' }}
                                type='number'
                                placeholder='$00000'
                                value={selValue}
                                onChange={(e: any) => setSelValue(e.target.value)}
                            />
                        </OptionWrapper>
                    </ContentWrapper>
                </InfoContainer>

                <AddWrapper>
                    <AddScheduleButton type='button' onClick={() => handleAddSchedule(selBox, days, selTime, selValue)}>
                        <FontAwesomeIcon icon={faPlus} />
                        Agregar
                    </AddScheduleButton>
                </AddWrapper>
                <RentsWrapper>
                    {schedules?.map((schedule: any, index: number) => {
                        return (
                            <RentItem key={index}>
                                <RentItemText>{`${schedule?.boxName} - ${schedule?.day} - ${schedule?.time} - $${schedule?.value}`}</RentItemText>
                                <FontAwesomeIcon onClick={() => handleRemoveRent(index)} icon={faTimes} />
                            </RentItem>
                        )
                    })}
                </RentsWrapper>
            </Card>
        </FormContentWrapper>
    )
}

export default DaysRent
