// Modules
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Fireworks } from 'fireworks-js/dist/react'

// Images
import FlipItLogo from '../../../assets/image/svg/Logo_Flipit_1.svg'
import CompletedImg from '../../../assets/image/png/completed.png'
import { useHistory } from 'react-router-dom'

const HomeContainer = styled('div')`
    width: 100%;
    height: 100vh;
    display: flex;
`

const LeftWrapper = styled('div')`
    ${({ theme }) => `
    width: 50%;
    height: 100vh;
    background-color: #2E4DCA;
    background-position: center;
    background-size: cover;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    padding-left:5rem;

    @media screen and (max-width:400px){
     padding:${theme?.spacing?.spc35};
     
    }
    @media screen and (max-width: 1000px){
      width: 100%; 
      justify-content:flex-start;
     
     
    }
    `}
`

const Title = styled('p')`
    ${({ theme }) => `
    color: ${theme?.colors?.base};
    font-size: ${theme?.fontSizes?.font40};
    font-family: raleway;
    font-weight: bold;
    font-weight:100;
    margin: 0;

    @media screen and (max-width:500px){
    font-size:${theme?.fontSizes?.font26}
  }
    @media screen and (min-width:501px and max-width:1000px){
    font-size: ${theme?.fontSizes?.font30}
  }

  `}
`

const Description = styled('p')`
    ${({ theme }) => `
    color: ${theme?.colors?.white};
    font-size: ${theme?.fontSizes?.font35};
    margin: 0;
    font-family: raleway-thin;
    font-weight: bold;

  @media screen and (max-width:500px){
    font-size:${theme?.fontSizes?.font26}
  }
  @media screen and (min-width:501px and max-width:1000px){
    font-size: ${theme?.fontSizes?.font30}
  }
  `}
`

const Mark = styled('a')`
    ${({ theme }) => `
    color: ${theme?.colors?.base};
    font-size: ${theme?.fontSizes?.font40};
    font-family: raleway;
    font-weight: bold;
    font-weight:100;
    margin: 0;

    @media screen and (max-width:500px){
    font-size:${theme?.fontSizes?.font26}
  }
    @media screen and (min-width:501px and max-width:1000px){
    font-size: ${theme?.fontSizes?.font30}
  }
  `}
`

const RightWrapper = styled('div')`
    ${({ theme }) => `
    width: 50%;
    height: 100vh;
    background-image: url(${CompletedImg});
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px){
      display: none;
    }
  `}
`

const LogoFlipIt = styled('img')`
    ${({ theme }) => `
   width: 210px;
  height: 70px;
  margin-bottom:${theme?.spacing?.spc35};
  margin-top:${theme?.spacing?.spc35};
  `}
`

function Completed() {
    const options = {
        delay: { min: 15, max: 30 },
        speed: 4,
        acceleration: 1.05,
        friction: 0.95,
        gravity: 1.5,
        particles: 50,
        trace: 3,
        explosion: 6,
        autoresize: true,
    }

    const style: Object = {
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
    }
    // Routes
    const history = useHistory()

    // Use Effects
    useEffect(() => {
        setTimeout(() => {
            history.push('/')
        }, 3500)
    }, [history])

    return (
        <>
            <Fireworks options={options} style={style} />
            <HomeContainer>
                <LeftWrapper>
                    <LogoFlipIt src={FlipItLogo} alt='FlipIt' />
                    <Title>
                        Gracias por unirte <br />a nosotros!
                    </Title>
                    <Description>
                        Bienvenido a la plataforma
                        <br /> que va a revolucionar el <br /> mercado <Mark>odontológico!</Mark>
                    </Description>
                </LeftWrapper>
                <RightWrapper />
            </HomeContainer>
        </>
    )
}

export default Completed
