// Modules
import { toast } from 'react-toastify'

// Types
import { TOAST_SUCCESS, TOAST_DANGER, TOAST_WARNING, TOAST_INFO } from './types'

const toastSuccess = (message: string) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return {
        type: TOAST_SUCCESS
    }
}

const toastDanger = (message: string) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return {
        type: TOAST_DANGER
    }
}

const toastWarning = (message: string) => {
    toast.warning(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return {
        type: TOAST_WARNING
    }
}

const toastInfo = (message: string) => {
    toast.info(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return {
        type: TOAST_INFO
    }
}

  
export { toastSuccess, toastDanger, toastWarning, toastInfo }
