import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
})

api.interceptors.request.use((config: any) => {
    const token = localStorage.getItem('token')
    config.headers['Pragma'] = 'no-cache'
    config.headers['Access-Control-Allow-Origin'] = '*'
    if (token) {
        config.headers.authorization = `Bearer ${token}`
    }

    return config
})

export default api
