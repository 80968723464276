// Modules
import React from 'react'
import styled from 'styled-components'

//Icons
import Recepcionista from '../../../assets/image/svg/Que servicios incluye/Recepcionista.svg'
import SaladeRayos from '../../../assets/image/svg/Que servicios incluye/Sala de Rayos.svg'
import PabellonCirugia from '../../../assets/image/svg/Que servicios incluye/Pabellon cirugia.svg'
import Cocina from '../../../assets/image/svg/Que servicios incluye/Cocina.svg'

import ParkingGreen from '../../../assets/image/svg/Que servicios incluye/Parking_green.svg'
import FreeGreen from '../../../assets/image/svg/Que servicios incluye/Free_green.svg'
import CashGreen from '../../../assets/image/svg/Que servicios incluye/$$_green.svg'

import ParkingWhite from '../../../assets/image/svg/Que servicios incluye/Parking_white.svg'
import FreeWhite from '../../../assets/image/svg/Que servicios incluye/Free.svg'
import CashWhite from '../../../assets/image/svg/Que servicios incluye/$$_white.svg'

const ServiceImages = styled.img`
    ${({ theme }) => `
  width:2.2rem;
  height:2.2rem;
`}
`

const ParkingImage = styled.img`
    ${({ theme }) => `
  width:3rem;
  height:1.7rem;
`}
`
const CashImages = styled.img`
    ${({ theme }) => `
  width:2rem;
  height:1.5rem;
`}
`

const servicesList = [
    {
        title: 'Recepcionista',
        icon: <ServiceImages src={Recepcionista} alt='recepcionista' />,
    },

    {
        title: 'Sala de rayos',
        icon: <ServiceImages src={SaladeRayos} alt='sala de rayos' />,
    },

    {
        title: 'Pabellón de cirugía',
        icon: <ServiceImages src={PabellonCirugia} alt='pabellón de ciurgia' />,
    },

    {
        title: 'Cocina',
        icon: <ServiceImages src={Cocina} alt='cocina' />,
    },
]

const parkingList = [
    {
        firstIconGreen: <ParkingImage src={ParkingGreen} alt='Parking green' />,
        firstIconWhite: <ParkingImage src={ParkingWhite} alt='Parking white' />,
        title: 'Estacionamiento dentistas',
        secondeIconGreen: <CashImages src={FreeGreen} alt='Free' />,
        secondeIconWhite: <CashImages src={FreeWhite} alt='Free White' />,
    },
    {
        firstIconGreen: <ParkingImage src={ParkingGreen} alt='Parking green' />,
        firstIconWhite: <ParkingImage src={ParkingWhite} alt='Parking white' />,
        title: 'Estacionamiento dentistas',
        secondeIconGreen: <CashImages src={CashGreen} alt='$$green' />,
        secondeIconWhite: <CashImages src={CashWhite} alt='Free' />,
    },
    {
        firstIconGreen: <ParkingImage src={ParkingGreen} alt='Parking green' />,
        firstIconWhite: <ParkingImage src={ParkingWhite} alt='Parking white' />,
        title: 'Sin estacionamiento',
    },
]

const parkingTwoList = [
    {
        firstIconGreen: <ParkingImage src={ParkingGreen} alt='Parking green' />,
        firstIconWhite: <ParkingImage src={ParkingWhite} alt='Parking white' />,
        title: 'Estacionamiento paciente',
        secondeIconGreen: <CashImages src={FreeGreen} alt='Free' />,
        secondeIconWhite: <CashImages src={FreeWhite} alt='Free White' />,
    },
    {
        firstIconGreen: <ParkingImage src={ParkingGreen} alt='Parking green' />,
        firstIconWhite: <ParkingImage src={ParkingWhite} alt='Parking white' />,
        title: 'Estacionamiento paciente',
        secondeIconGreen: <CashImages src={CashGreen} alt='$$green' />,
        secondeIconWhite: <CashImages src={CashWhite} alt='Free' />,
    },
]

export { servicesList, parkingList, parkingTwoList }
