// Modules
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Container, Breadcrumbs } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useDispatch } from 'react-redux'

// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { faHome } from '@fortawesome/free-solid-svg-icons'

// Components
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { BreadcrumbItem, Loading } from '../components'
import { toastDanger } from '../redux/actions/toast/action'

// Routes
import history from '../services/history'

// Services
import BoxService from '../services/box/box'

const PrincipalContainer = styled('div')`
    ${({ theme }) => `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #f5f5f5;
        margin-top: ${theme?.spacing?.spc60};
        margin-bottom: ${theme?.spacing?.spc50};
    `}
`

const GeneralBox = styled('div')`
    ${({ theme }) => `
        width: 100%;
        height: 22rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${theme?.colors?.white};
        border-radius: 8px;
    `}
`

const ClinicWrapper = styled('div')`
    ${({ theme }) => `
        width: 18rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    `}
`
const ClinicImage = styled('img')`
    ${({ theme }) => `
        width: 100%;
        height: 10rem;
        border-radius: ${theme?.spacing?.spc16};
        margin-top: 1rem;
    `}
`

const Subtitle = styled('p')`
    ${({ theme }) => `
        color: ${theme?.colors?.greyBase};
        font-weight: bold;
        margin-bottom: 0;
        font-size: ${theme?.fontSizes?.font22};
        font-family: raleway;
    `}
`

const AddressName = styled('div')`
    ${({ theme }) => `
        color: ${theme?.colors?.black};
        font-size: ${theme?.fontSizes?.font20};
        font-family: raleway-thin;
        height: 5rem;
        span{
            text-overflow: ellipsis;
        }
    `}
`

const ClinicButton = styled('button')`
    ${({ theme }) => `
        width: 100%;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${theme?.colors?.base};
        color: ${theme?.colors?.white};
        font-size: ${theme?.fontSizes?.font20};
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-family: raleway;
    `}
`

const FlipitClinic = () => {
    // States
    const [clinics, setClinics] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    // Dispatch
    const dispatch = useDispatch()

    // Use Effects
    useEffect(() => {
        const boxService = new BoxService()
        boxService
            .getBoxList()
            .then((resp: any) => {
                setClinics(resp?.data?.data?.data)
                setIsLoading(false)
            })
            .catch(err => {
                dispatch(toastDanger('Error, inténtelo de nuevo más tarde!'))
                setIsLoading(false)
            })
    }, [dispatch])

    const breadcrumbs = [<BreadcrumbItem text={<FontAwesomeIcon icon={faHome} />} />]

    const changeTextSize = (text: string) => {
        if (text.length > 90) {
            return text.substring(0, 90) + '...'
        } else {
            return text
        }
    }

    return (
        <>
            <Header />
            {!isLoading ? (
                <PrincipalContainer>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '2rem' }}>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container>
                        <Grid container spacing={2}>
                            {clinics.map((clinic: any, index: number) => {
                                const address = JSON.parse(clinic?.address).name
                                return (
                                    <Grid item xs={12} md={4} lg={4} xl={4} key={index}>
                                        <GeneralBox>
                                            <ClinicWrapper>
                                                <ClinicImage src={clinic?.images[0]?.url} alt={clinic?.boxName} />
                                                <Subtitle>{clinic?.name}</Subtitle>
                                                <AddressName>
                                                    <span>{changeTextSize(address)}</span>
                                                </AddressName>
                                                <ClinicButton
                                                    onClick={() => history.push(`/box/details/${clinic?.slug}`)}
                                                >
                                                    Ver box
                                                </ClinicButton>
                                            </ClinicWrapper>
                                        </GeneralBox>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>
                </PrincipalContainer>
            ) : (
                <Loading />
            )}
            <Footer />
        </>
    )
}

export default FlipitClinic
