// Modules
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormContentWrapper } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'

// Components
import Card from '../../../components/Card'

// Data
import { servicesList, parkingList, parkingTwoList } from './ServicesAvailable.data'

// Routes
import history from '../../../services/history'
import { toastDanger } from '../../../redux/actions/toast/action'
import { StateServiceAvailableIT } from '../../../redux/reducers/registerForms/interface'
import { UpdateServicesIT } from './interface'
import { BoxService } from '../../../services'
import { SavingBox } from '../../../redux/actions/registerForms/action'
import { useLocation } from 'react-router-dom'

const ServicesWrapper = styled('div')`
    ${({ theme }) => `
    width:100%;
    height:auto;
    display: flex;
    flex-direction:column;
    margin-right:${theme?.spacing?.spc45};
    border-radius: ${theme?.spacing?.spc6};

    @media screen and (max-width:400px){
      align-itemns:center;
      justify-content:center;
    }
`}
`

interface Props {
    active: boolean
}

const ServicesItem = styled('div')<Props>`
    ${({ theme, active }) => `
    height: 4.8rem;
    width: 5.2rem;
    display:flex;
    flex-direction:column;
    border-radius: ${theme?.spacing?.spc6};
    padding: ${theme?.spacing?.spc4};
    margin-right:${theme?.spacing?.spc10};
    border: 1px solid ${theme?.colors?.greyLight};
    cursor:pointer;
    ${
        active
            ? `background-color:${theme?.colors?.base};
      color:${theme?.colors?.white};
      p{
        color:${theme?.colors?.white};
      }`
            : ``
    }
    &:hover{
      background-color:${theme?.colors?.base};
      color:${theme?.colors?.white};
      p{
        color:${theme?.colors?.white};
      }
    }
    @media screen and (max-width:768px){
        width: 7.5rem;
        margin-top:${theme?.spacing?.spc10};
    }
  `}
`

const ServiceTitle = styled('p')`
    ${({ theme }) => `
        color:${theme?.colors?.secondBase};
        font-size:${theme?.fontSizes?.font9};
        font-weight:bold;
        font-family: raleway;
        text-align:center;
    `}
`

const IconWrapper = styled('div')`
    ${({ theme }) => `
        width:auto;
        height:auto;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:${theme?.spacing?.spc10};
        svg{
            font-size:${theme?.fontSizes?.font24};
            color${theme?.colors?.secondBase};
        }
  `}
`
const ServicesContainer = styled('div')`
    ${({ theme }) => `
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content:center;
        @media screen and (max-width:768px){
            width:100%;
        }
`}
`

const TextSpace = styled('div')`
    ${({ theme }) => `
        width: 100%;
        height: auto;
        display: flex;
        margin-top:${theme?.spacing?.spc4};
    `}
`
const Subtitle = styled('h3')`
    ${({ theme }) => `
        color:${theme?.colors?.secondBase};
        font-size:${theme?.fontSizes?.font16};
        font-family: raleway;
    `}
`
const ParkingWrapper = styled('div')`
    ${({ theme }) => `
  width: 100%;
  height: auto;
  display: flex;
  
  @media screen and (max-width:768px){
    flex-direction: column;
  }
`}
`
const FirstParking = styled('div')`
    ${({ theme }) => `
    width:50%;
    height:auto;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;

    @media screen and (max-width:768px){
      width: 100%;
    }
  `}
`
const SecondParking = styled('div')`
    ${({ theme }) => `
    width:50%;
    height:auto;
    display:flex;
    align-items:center;
    flex-direction:column;
    margin-left:${theme?.spacing?.spc8};

    @media screen and (max-width:768px){
      width: 100%;
    }
  `}
`
const ItemParking = styled('div')<Props>`
    ${({ theme, active }) => `
    width:95%;
    height:2rem;
    display:flex;
    cursor: pointer;
    padding:${theme?.spacing?.spc4};
    margin-top:${theme?.spacing?.spc8};
    border-radius:${theme?.spacing?.spc6};
    border: 1px solid #e1e1e1;
    ${
        active
            ? `background-color:${theme?.colors?.base};
      color:${theme?.colors?.white};
      p{
        color:${theme?.colors?.white};
      }`
            : ``
    }
    &:hover{
      background-color:${theme?.colors?.base};
      color:${theme?.colors?.white};
      p{
        color:${theme?.colors?.white};
      }
    }
`}
`
const IconParking = styled('div')`
    ${({ theme }) => `
        width:auto;
        height:auto;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:${theme?.spacing?.spc10};
    `}
`

interface StateIT {
    text: string
    active: boolean
}

function ServicesAvailable() {
    // States
    const [isHoveringLeft, setIsHoveringLeft] = useState(-1)
    const [isHoveringRight, setIsHoveringRight] = useState(-1)

    const [isLoading, setIsLoading] = useState(false)
    const [loadInfo, setLoadInfo] = useState(true)

    const [serviceList, setServiceList] = useState([
        { text: 'Recepcionista', active: false },
        { text: 'Sala de rayos', active: false },
        { text: 'Pabellón de cirugía', active: false },
        { text: 'Cocina', active: false },
    ])

    const [prkDentistList, setPrkDentistList] = useState([
        { text: 'Estacionamiento dentistas - Free', active: false },
        { text: 'Estacionamiento dentistas - Pay', active: false },
        { text: 'Sin estacionamiento', active: false },
    ])

    const [prkPacientList, setPrkPacientList] = useState([
        { text: 'Estacionamiento pacients - Free', active: false },
        { text: 'Estacionamiento pacients - Pay', active: false },
    ])

    // Reducers
    const {
        registerForms: {
            boxes: { data },
        },
    }: any = useSelector(state => state)

    // Dispatch
    const dispatch = useDispatch()

    // Routes
    const { search } = useLocation()
    const summary = search?.split('=')[1]

    // UseEffects
    useEffect(() => {
        if (data[0] && loadInfo) {
            let newServiceList: React.SetStateAction<{ text: string; active: boolean }[]> | any = []
            serviceList?.forEach(serviceItem => {
                let flag: boolean = false
                data[0]?.services?.forEach((loadedItem: any) => {
                    if (serviceItem.text === loadedItem) {
                        flag = true
                    }
                })
                if (flag) {
                    newServiceList.push({ text: serviceItem.text, active: true })
                } else {
                    newServiceList.push(serviceItem)
                }
            })
            setServiceList(newServiceList)

            let newPrkDentistList: React.SetStateAction<{ text: string; active: boolean }[]> | any = []
            prkDentistList?.forEach(prkDentistItem => {
                let flag: boolean = false
                data[0]?.parkingDentist?.forEach((loadedItem: any) => {
                    if (prkDentistItem.text === loadedItem) {
                        flag = true
                    }
                })
                if (flag) {
                    newPrkDentistList.push({ text: prkDentistItem.text, active: true })
                } else {
                    newPrkDentistList.push(prkDentistItem)
                }
            })
            setPrkDentistList(newPrkDentistList)

            let newPrkPacientList: React.SetStateAction<{ text: string; active: boolean }[]> | any = []
            prkPacientList?.forEach(prkClientItem => {
                let flag: boolean = false
                data[0]?.parkingClient?.forEach((loadedItem: any) => {
                    if (prkClientItem.text === loadedItem) {
                        flag = true
                    }
                })
                if (flag) {
                    newPrkPacientList.push({ text: prkClientItem.text, active: true })
                } else {
                    newPrkPacientList.push(prkClientItem)
                }
            })
            setPrkPacientList(newPrkPacientList)

            setLoadInfo(false)
        }
    }, [data, loadInfo, prkDentistList, prkPacientList, serviceList])

    // Methods
    const handleAddService = (keyNumber: number) => {
        const newState = serviceList.map((item: StateServiceAvailableIT, index: number) => {
            if (keyNumber === index) {
                item.active = !item.active
            }
            return item
        })
        setServiceList(newState)
    }

    const handleAddPrkDentist = (keyNumber: number) => {
        const newState = prkDentistList.map((item: StateServiceAvailableIT, index: number) => {
            if (keyNumber === index) {
                item.active = !item.active
            }
            return item
        })
        setPrkDentistList(newState)
    }

    const handleAddPrkPacient = (keyNumber: number) => {
        const newState = prkPacientList.map((item: StateServiceAvailableIT, index: number) => {
            if (keyNumber === index) {
                item.active = !item.active
            }
            return item
        })
        setPrkPacientList(newState)
    }

    const isValid = (
        arrService: Array<StateIT>,
        arrPrkDentist: Array<StateIT>,
        arrprkPacient: Array<StateIT>,
    ): boolean => {
        let isValidPrkDentist: boolean = false
        let isValidPrkPacient: boolean = false

        arrPrkDentist?.forEach((elem: StateIT) => {
            if (elem?.active) {
                isValidPrkDentist = true
            }
        })
        if (!isValidPrkDentist) {
            dispatch(toastDanger('Debe seleccionar al menos una opción de estacionamiento para dentista!'))
        }

        arrprkPacient?.forEach((elem: StateIT) => {
            if (elem?.active) {
                isValidPrkPacient = true
            }
        })
        if (!isValidPrkPacient) {
            dispatch(toastDanger('Debe seleccionar al menos una opción de estacionamiento para clientes!'))
        }

        if (isValidPrkDentist && isValidPrkPacient) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = async () => {
        if (isValid(serviceList, prkDentistList, prkPacientList)) {
            setIsLoading(true)
            const arrServices: Array<string> = []
            serviceList.forEach((item: StateIT) => {
                if (item.active) arrServices.push(item.text)
            })

            const arrPrkDentists: Array<string> = []
            prkDentistList.forEach((item: StateIT) => {
                if (item.active) arrPrkDentists.push(item.text)
            })

            const arrPrkClients: Array<string> = []
            prkPacientList.forEach((item: StateIT) => {
                if (item.active) arrPrkClients.push(item.text)
            })

            const saveData: Array<UpdateServicesIT> = data.map((item: any) => {
                const newItem = {
                    id: item?._id,
                    boxName: item?.name,
                    boxSize: item?.boxSize,
                    services: arrServices,
                    parkingDentist: arrPrkDentists,
                    parkingClient: arrPrkClients,
                }
                return newItem
            })
            const boxService = new BoxService()
            boxService
                .updateServices(saveData)
                .then(resp => {
                    dispatch(SavingBox(resp))
                    if (summary) {
                        history.push('/onboarding/summary')
                    } else {
                        history.push('/onboarding/days-rent')
                    }
                    setIsLoading(false)
                })
                .catch(err => {
                    dispatch(toastDanger('Error, inténtelo de nuevo más tarde!'))
                    setIsLoading(false)
                })
        }
    }

    return (
        <FormContentWrapper>
            <Card
                mark={'Qué servicios'}
                title={'estan disponibles en el box?'}
                current={5}
                backLink={'/onboarding/materials-available'}
                onSubmit={handleSubmit}
                isLoading={isLoading}
            >
                <ServicesWrapper>
                    <TextSpace>
                        <Subtitle>Selecciona los servicios</Subtitle>
                    </TextSpace>
                    <ServicesContainer>
                        {servicesList?.map((item, index: number) => {
                            return (
                                <ServicesItem
                                    key={item?.title}
                                    onClick={() => handleAddService(index)}
                                    active={serviceList[index]?.active}
                                >
                                    <IconWrapper>{item?.icon}</IconWrapper>
                                    <ServiceTitle>{item?.title}</ServiceTitle>
                                </ServicesItem>
                            )
                        })}
                    </ServicesContainer>
                    <TextSpace>
                        <Subtitle>Estacionamientos</Subtitle>
                    </TextSpace>
                    <ParkingWrapper>
                        <FirstParking>
                            {parkingList?.map((item, index) => {
                                return (
                                    <ItemParking
                                        key={index}
                                        onMouseEnter={() => setIsHoveringLeft(index)}
                                        onMouseLeave={() => setIsHoveringLeft(-1)}
                                        onClick={() => handleAddPrkDentist(index)}
                                        active={prkDentistList[index]?.active}
                                    >
                                        <IconParking>
                                            {isHoveringLeft === index || prkDentistList[index]?.active
                                                ? item?.firstIconWhite
                                                : item?.firstIconGreen}
                                        </IconParking>
                                        <ServiceTitle>{item?.title}</ServiceTitle>
                                        <IconParking>
                                            {isHoveringLeft === index || prkDentistList[index]?.active
                                                ? item?.secondeIconWhite
                                                : item?.secondeIconGreen}
                                        </IconParking>
                                    </ItemParking>
                                )
                            })}
                        </FirstParking>

                        <SecondParking>
                            {parkingTwoList.map((item, index) => {
                                return (
                                    <ItemParking
                                        key={index}
                                        onMouseEnter={() => setIsHoveringRight(index)}
                                        onMouseLeave={() => setIsHoveringRight(-1)}
                                        onClick={() => handleAddPrkPacient(index)}
                                        active={prkPacientList[index]?.active}
                                    >
                                        <IconParking>
                                            {isHoveringRight === index || prkPacientList[index]?.active
                                                ? item?.firstIconWhite
                                                : item?.firstIconGreen}
                                        </IconParking>
                                        <ServiceTitle>{item?.title}</ServiceTitle>
                                        <IconParking>
                                            {isHoveringRight === index || prkPacientList[index]?.active
                                                ? item?.secondeIconWhite
                                                : item?.secondeIconGreen}
                                        </IconParking>
                                    </ItemParking>
                                )
                            })}
                        </SecondParking>
                    </ParkingWrapper>
                </ServicesWrapper>
            </Card>
        </FormContentWrapper>
    )
}

export default ServicesAvailable
