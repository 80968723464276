// Modules
import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//IcONS
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

// Images
import FlipItLogo from '../../assets/image/svg/logo-white.svg'
import DoctorChair from '../../assets/image/svg/doctorchair.svg'
import { useHistory } from 'react-router'

const StartRegisterContainer = styled('div')`
    width: 100%;
    height: 100vh;
    display: flex;
`

const LeftWrapper = styled('div')`
    ${({ theme }) => `
    width: 50%;
    height: 100vh;
    background-color: #2E4DCA;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    @media screen and (max-width: 768px){
      width: 100%;
    }
    `}
`

const BoxWrapper = styled('div')`
    ${({ theme }) => `
    max-width: 400px;
    padding: ${theme?.spacing?.spc30};
  `}
`

const LinkGoBack = styled('a')`
    ${({ theme }) => `
      position: absolute;
      top:20px;
      left: 20px;
      color: ${theme?.colors?.white};
      font-size: ${theme?.fontSizes?.font22};
      font-family: raleway;
  `}
`

const TextGreen = styled('span')`
    ${({ theme }) => `
    color: ${theme?.colors?.base};
  `}
`

const Title = styled('p')`
    ${({ theme }) => `
    margin: 0;
    color: ${theme?.colors?.white};
    font-size: ${theme?.fontSizes?.font40};
    font-family: raleway;
    font-weight: bold;
    
    @media screen and (max-width:768px){
      font-size: ${theme?.fontSizes?.font27};
    }
  `}
`

const TitleThin = styled('p')`
    ${({ theme }) => `
    margin: 0;
    color: ${theme?.colors?.white};
    font-size: ${theme?.fontSizes?.font35};
    font-family: raleway-thin;
    font-weight: 100;

    @media screen and (max-width:768px){
      font-size: ${theme?.fontSizes?.font27};
    }
  `}
`

const RightWrapper = styled('div')`
    ${({ theme }) => `
    width: 50%;
    height: 100vh;
    background-image: url(${DoctorChair});
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px){
      display: none;
    }
  `}
`

const LogoFlipIt = styled('img')`
    ${({ theme }) => `
    width: 200px;
    height: auto;
    margin-bottom: ${theme?.spacing?.spc30};
 `}
`

const ButtonStart = styled('button')`
    ${({ theme }) => `
    border: none;
    background: none;
    font-size: ${theme?.fontSizes?.font27};
    color: ${theme?.colors?.base};
    margin-top: ${theme?.spacing?.spc35};
    font-weight: bold;
    cursor: pointer;
    font-family: raleway;
    
    &:hover{
      text-decoration: underline;
    }
    
    svg{
      color: ${theme?.colors?.base};
      margin-left: ${theme?.spacing?.spc10};
    }
`}
`

function StartRegister() {
    const history = useHistory()

    return (
        <StartRegisterContainer>
            <LeftWrapper>
                <LinkGoBack href='https://flipit.gux.cl/'> {'<'} Ir al inicio</LinkGoBack>
                <BoxWrapper>
                    <a href='https://flipit.gux.cl/' style={{ textDecoration: 'none' }}>
                        <LogoFlipIt src={FlipItLogo} alt='FlipIt' />
                    </a>
                    <Title>
                        Registra tú <TextGreen>BOX</TextGreen>
                    </Title>
                    <TitleThin>y empieza a recibir solicitudes de arriendo!</TitleThin>
                    <ButtonStart onClick={() => history.push('/onboarding/address')}>
                        Comencemos
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                    </ButtonStart>
                </BoxWrapper>
            </LeftWrapper>
            <RightWrapper />
        </StartRegisterContainer>
    )
}

export default StartRegister
