import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import styled from 'styled-components'

const ContentWrrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-item: center;
    justify-content: center;
    flex-direction: column;
  `}
`

const SearchInput = styled('input')`
    ${({ theme }) => `
    width: 100%;
    height: 2rem;
    font-family: raleway;
    margin-bottom: ${theme?.spacing?.spc30};
    outline: none;
    border-radius: ${theme?.spacing?.spc4};
    border:0.5px solid ${theme?.colors?.secondBase};
  `}
`

const ListWrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    position: relative;
    list-style: none;
  `}
`

const PositionList = styled('ul')`
    ${({ theme }) => `
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    position: absolute;
    top: -15px;
    right: 0;
    z-index: 2;
    background: ${theme?.colors?.white};
    border: 1px solid ${theme?.colors?.secondBase};
    border-radius: ${theme?.spacing?.spc4};
  `}
`

type Props = {
    stripped: number
}

const ListItem = styled('li')<Pick<Props, 'stripped'>>`
    ${({ theme, stripped }) => `
    padding: ${theme?.spacing?.spc6} ${theme?.spacing?.spc10};
    cursor: pointer;
    border-radius: ${theme?.spacing?.spc4};
    background: ${stripped % 2 ? '#f5f5f5' : '#fff'};

    &:hover{
      background: ${theme?.colors?.greyLight};
    }
  `}
`

const searchOptions = {
    componentRestrictions: { country: 'cl' },
}

function MapsAutoComplete({ setPosition, handleChange, state, setState }: any) {
    const handleSelect = (newState: any) => {
        setState(newState)
        geocodeByAddress(newState)
            .then(results => getLatLng(results[0]))
            .then(latLng => setPosition(latLng))
            .catch(error => console.error('Error', error))
    }

    return (
        <PlacesAutocomplete value={state} onChange={handleChange} onSelect={handleSelect} searchOptions={searchOptions}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <ContentWrrapper>
                    <SearchInput
                        {...getInputProps({
                            placeholder: 'Escribe la dirección de tu Box aquí...',
                            className: 'location-search-input',
                        })}
                    />
                    {loading && <div>Cargando...</div>}
                    <ListWrapper>
                        {suggestions.length > 0 && (
                            <PositionList>
                                {suggestions.map((suggestion: any, index: number) => {
                                    return (
                                        <ListItem {...getSuggestionItemProps(suggestion)} key={index} stripped={index}>
                                            <span>{suggestion.description}</span>
                                        </ListItem>
                                    )
                                })}
                            </PositionList>
                        )}
                    </ListWrapper>
                </ContentWrrapper>
            )}
        </PlacesAutocomplete>
    )
}

export default MapsAutoComplete
