// Modules
import React, { useEffect, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import styled from 'styled-components'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { useLocation, useParams } from 'react-router-dom'
import { Grid, Container, Breadcrumbs } from '@mui/material'
import Calendar from 'react-calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useDispatch } from 'react-redux'

// Utils
import { contactWhatsapp } from '../../helpers/Utils'

// Redux Actions
import { toastDanger } from '../../redux/actions/toast/action'

// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faHome } from '@fortawesome/free-solid-svg-icons'

// Components
import Header from '../../Header/Header'
import { BreadcrumbItem, Loading } from '../../components'
import Footer from '../../Footer/Footer'

// Services
import { BoxService } from '../../services'

const ClinicDetailsWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        height: auto;
        display: flex;
        background: ${theme?.colors?.white};
        padding-top: ${theme?.spacing?.spc60};
        padding-bottom: ${theme?.spacing?.spc50};
    `}
`
const TitleBox = styled('h2')`
    ${({ theme }) => `
        color: ${theme?.colors?.base};
        text-align: center;
    `}
`

const GaleryWrapper = styled('div')`
    ${({ theme }) => `
        width: 100%;
        height: 20rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    `}
`

const GaleryMainImage = styled('img')`
    ${({ theme }) => `
        width: 80%;
        height: 20rem;
        border-radius: ${theme?.spacing?.spc10};

        @media screen and (max-width: 425px){
            width: 100%;
            max-width: 18rem;
            height: 15rem;
        }
    `}
`

const GaleryImages = styled('div')`
    ${({ theme }) => `
        width: 20%;
        height: 20rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        @media screen and (max-width: 425px){
            display: none;
    `}
`

const ImageItem = styled('img')`
    ${({ theme }) => `
        width: 90%;
        height: 5rem;
        border-radius: ${theme?.spacing?.spc10};
        cursor: pointer;
    `}
`

const ButtonAgendarBox = styled('button')`
    ${({ theme }) => `
        width: 100%;
        border-radius: ${theme?.spacing?.spc10};
        background: ${theme?.colors?.base};
        color: ${theme?.colors?.white};
        border: none;
        cursor: pointer;
        font-size: ${theme?.fontSizes?.font24};
        font-family: raleway;
        margin-top: ${theme?.spacing?.spc10};
        height: 2.5rem;

        &:hover{
        background: ${theme?.colors?.secondBase};
        color: ${theme?.colors?.white};
       }
    `}
`

const OptionsRentWrapper = styled('div')`
    ${({ theme }) => `
       width: 100%;
       display:flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
    `}
`

const JonadaBox = styled('div')`
    ${({ theme }) => `
       width: 100%;
       background: ${theme?.colors?.base};
       color: ${theme?.colors?.white};
       height: 2rem;
       display:flex;
       align-items: center;
       justify-content: center;
       font-size: ${theme?.fontSizes?.font24};
       font-family: raleway;
       padding: ${theme?.spacing?.spc4};
       border-radius: ${theme?.spacing?.spc4};
    `}
`

const JonadaItem = styled('div')`
    ${({ theme }) => `
       width: 100%;
       background: ${theme?.colors?.white};
       color: ${theme?.colors?.base};
       height: 2rem;
       display:flex;
       align-items: center;
       justify-content: center;
       font-size: ${theme?.fontSizes?.font18};
       font-family: raleway;
       padding: ${theme?.spacing?.spc4};
       border-radius: ${theme?.spacing?.spc4};
       border: 1px dashed ${theme?.colors?.greyBase};
       cursor: pointer;
       
       &:hover{
           background: ${theme?.colors?.secondBase} !important;
           color: ${theme?.colors?.white};
        }
        `}
`

const NoOptionAvailable = styled('p')`
    ${({ theme }) => `
       font-size: ${theme?.fontSizes?.font18};
       font-family: raleway;
       text-align: center;
       color: ${theme?.colors?.greyBase};
    `}
`
const BoxDescriptionWrapper = styled('div')`
    ${({ theme }) => `
       width: 100%;
       display:flex;
       align-items: flex-start;
       justify-content: flex-start;
       flex-direction: column;
       padding: 0 ${theme?.spacing?.spc10};
    `}
`

const CalendarWrapper = styled('div')`
    ${({ theme }) => `
       
       .react-calendar{
        border: none;
        width: 100%;
       }

       .react-calendar button{
           border: 1px solid ${theme?.colors?.greyLight};
       }

       .react-calendar__tile--active{
            background: ${theme?.colors?.base};
       }
    `}
`

const BoxDescriptionTitle = styled('h3')`
    ${({ theme }) => `
       color: ${theme?.colors?.greyBase};
       font-family: raleway;
       margin-top: 0;
    `}
`

const BoxDescriptionList = styled('ul')`
    ${({ theme }) => `
       list-style:none;
       margin:0;
       padding: 0;
    `}
`

const BoxDescriptionListItem = styled('li')`
    ${({ theme }) => `
       font-family: raleway-thin;
       font-size: ${theme?.fontSizes?.font18};
       color: ${theme?.colors?.black};
       
       svg{
           color: ${theme?.colors?.base};
           margin-right: ${theme?.spacing?.spc6};
       }
    `}
`

const lib: any = ['places']
const mapStyles = {
    height: '20rem',
    width: '100%',
}

interface GaleryItemsIT {
    firstImage: string
    secondImage: string | undefined | null
    thirdImage: string | undefined | null
    setImage: any
}

function GaleryItems({ firstImage, secondImage, thirdImage, setImage }: GaleryItemsIT) {
    return (
        <GaleryImages>
            <ImageItem src={firstImage} alt='main image' onClick={() => setImage(firstImage)} />
            {secondImage && (
                <ImageItem
                    src={secondImage}
                    alt='second image'
                    style={{ marginTop: '1rem' }}
                    onClick={() => setImage(secondImage)}
                />
            )}
            {thirdImage && (
                <ImageItem
                    src={thirdImage}
                    alt='third image'
                    style={{ marginTop: '1rem' }}
                    onClick={() => setImage(thirdImage)}
                />
            )}
        </GaleryImages>
    )
}

const ClinicPage = () => {
    // States
    const [clinic, setClinic]: any = useState({})
    const [currImage, setCurrImage] = useState('')
    const [isLoadind, setIsLoading] = useState(true)
    const [dayValue, setDayValue] = useState(new Date())
    const [currentPosition, setCurrentPosition] = useState({
        lat: 0,
        lng: 0,
    })
    const [jornada, setJornada] = useState([])
    const [selectedJornada, setSelectedJornada] = useState('')

    // Routes
    const { slug }: any = useParams()
    const location = useLocation()

    // Dispatch
    const dispatch = useDispatch()

    // Use Effects
    useEffect(() => {
        const boxService = new BoxService()
        boxService
            .getBoxBySlug(slug)
            .then((resp: any) => {
                setClinic(resp?.data?.data)
                setCurrImage(resp?.data?.data?.images[0]?.url)
                const address: any = JSON.parse(resp?.data?.data?.address)
                setCurrentPosition(address?.position)
                setIsLoading(false)
            })
            .catch(err => {
                dispatch(toastDanger('Error, inténtelo de nuevo más tarde!'))
                setIsLoading(false)
            })
    }, [dispatch, slug])

    // Methods
    const findRentOptions = (rentsList: Array<any>, day: string) => {
        let newRentList: any = []
        rentsList.forEach((item: any) => {
            if (day === 'Monday' && item?.day === 'Lunes') {
                newRentList.push(item)
            } else if (day === 'Tuesday' && item?.day === 'Martes') {
                newRentList.push(item)
            } else if (day === 'Wednesday' && item?.day === 'Miércoles') {
                newRentList.push(item)
            } else if (day === 'Thursday' && item?.day === 'Jueves') {
                newRentList.push(item)
            } else if (day === 'Friday' && item?.day === 'Viernes') {
                newRentList.push(item)
            } else if (day === 'Saturday' && item?.day === 'Sábado') {
                newRentList.push(item)
            } else if (day === 'Sunday' && item?.day === 'Domingo') {
                newRentList.push(item)
            }
        })
        return newRentList
    }

    const handleSetDay = (calendarValue: any) => {
        const dayName: string = moment(calendarValue).locale('es').format('dddd')
        const newJornada = findRentOptions(clinic?.rents, dayName)
        setJornada(newJornada)
        setDayValue(calendarValue)
    }

    const handleSendMessage = (currDate: any, rentJornada: any) => {
        const boxPath = location.pathname
        const boxLink = process.env.REACT_APP_FRONT_END + boxPath.replace(/\s/g, '%20')
        const phoneWhatsapp = '56983416851'
        const dateSpanish = moment(currDate).locale('es').format('D MMMM YYYY')
        const whatsAppLink: string = contactWhatsapp(phoneWhatsapp, dateSpanish, boxLink, rentJornada)
        window.location.replace(whatsAppLink)
    }

    const breadcrumbs = [
        <BreadcrumbItem text={<FontAwesomeIcon icon={faHome} />} link={'/'} />,
        <BreadcrumbItem text={clinic?.name} />,
    ]

    return (
        <>
            <Header />
            {!isLoadind ? (
                <ClinicDetailsWrapper>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <TitleBox>{clinic?.name}</TitleBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                <GaleryWrapper>
                                    <GaleryItems
                                        firstImage={clinic?.images[0]?.url}
                                        secondImage={clinic?.images[1]?.url}
                                        thirdImage={clinic?.images[2]?.url}
                                        setImage={setCurrImage}
                                    />
                                    <GaleryMainImage src={currImage} alt='main image' />
                                </GaleryWrapper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <CalendarWrapper>
                                            <Calendar
                                                locale='es'
                                                onChange={(e: any) => handleSetDay(e)}
                                                value={dayValue}
                                            />
                                        </CalendarWrapper>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} xl={6}>
                                        <JonadaBox>Jornada</JonadaBox>
                                        <OptionsRentWrapper>
                                            {jornada && jornada?.length > 0 ? (
                                                jornada.map((item: any, index: number) => {
                                                    return (
                                                        <JonadaItem
                                                            key={index}
                                                            style={{
                                                                marginTop: '0.5rem',
                                                                backgroundColor:
                                                                    selectedJornada ===
                                                                    `${item?.period} - $${item?.price}`
                                                                        ? '#0b1843'
                                                                        : '#fff',
                                                            }}
                                                            onClick={() =>
                                                                setSelectedJornada(`${item?.period} - $${item?.price}`)
                                                            }
                                                        >
                                                            {`${item?.period} - $${item?.price}`}
                                                        </JonadaItem>
                                                    )
                                                })
                                            ) : (
                                                <NoOptionAvailable>
                                                    No hay opciones disponibles <br />
                                                    ese día
                                                </NoOptionAvailable>
                                            )}
                                        </OptionsRentWrapper>
                                    </Grid>
                                </Grid>
                                <ButtonAgendarBox onClick={() => handleSendMessage(dayValue, selectedJornada)}>
                                    {' '}
                                    <FontAwesomeIcon
                                        icon={faWhatsapp}
                                        style={{ color: '#fff', marginRight: '0.5rem' }}
                                    />
                                    {jornada && jornada?.length > 0 ? 'Agendar box' : 'Reservar este box'}
                                </ButtonAgendarBox>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                <LoadScript
                                    googleMapsApiKey={'AIzaSyBGnSNtaSmSt6S132pbwEYPmXQthC9xtis'}
                                    libraries={lib}
                                >
                                    <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={currentPosition}>
                                        {currentPosition.lat && <Marker position={currentPosition} />}
                                    </GoogleMap>
                                </LoadScript>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                <BoxDescriptionWrapper>
                                    <BoxDescriptionTitle>Descripción del box</BoxDescriptionTitle>
                                    <BoxDescriptionList>
                                        <BoxDescriptionListItem>
                                            <FontAwesomeIcon icon={faCheck} />
                                            {`Tamaño: ${clinic?.boxSize} m2`}
                                        </BoxDescriptionListItem>
                                        {clinic?.services?.length > 0 && (
                                            <BoxDescriptionListItem>
                                                <FontAwesomeIcon icon={faCheck} />
                                                {`Servicios: ${clinic?.services.join(', ')}`}
                                            </BoxDescriptionListItem>
                                        )}
                                        {clinic?.materials?.length > 0 && (
                                            <BoxDescriptionListItem>
                                                <FontAwesomeIcon icon={faCheck} />
                                                {`Materiales: ${clinic?.materials.join(', ')}`}
                                            </BoxDescriptionListItem>
                                        )}
                                        {clinic?.parkingClient?.length > 0 && (
                                            <BoxDescriptionListItem>
                                                <FontAwesomeIcon icon={faCheck} />
                                                {clinic?.parkingClient.join(', ')}
                                            </BoxDescriptionListItem>
                                        )}
                                        {clinic?.parkingDentist?.length > 0 && (
                                            <BoxDescriptionListItem>
                                                <FontAwesomeIcon icon={faCheck} />
                                                {clinic?.parkingDentist.join(', ')}
                                            </BoxDescriptionListItem>
                                        )}
                                    </BoxDescriptionList>
                                </BoxDescriptionWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ClinicDetailsWrapper>
            ) : (
                <Loading />
            )}
            <Footer />
        </>
    )
}

export default ClinicPage
