// Types
import { SAVING_BOX, SAVING_BOX_ADDRESS, SAVING_RENT, SAVING_USER, RESET_REGISTER_FORM } from './types'

export const SavingBox = (data: any) => {
    return { type: SAVING_BOX, payload: data }
}

export const SavingBoxAddress = (data: any) => {
    return { type: SAVING_BOX_ADDRESS, payload: data }
}

export const changePersonalData = (_id: string, name: string, email: string, phone: string) => {
    const payload = {
        _id,
        name,
        email,
        phone,
    }
    return { type: SAVING_USER, payload }
}

export const SavingRents = (data: any) => {
    return { type: SAVING_RENT, payload: data }
}

export const ResetRegisterForm = () => {
    return { type: RESET_REGISTER_FORM }
}
