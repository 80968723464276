// Modules
import React from 'react'
import styled from 'styled-components'

// Ícones
import cavity from '../../assets/image/svg/cavity.svg'
import dentalRecord from '../../assets/image/svg/dental-record.svg'
import dentistChair from '../../assets/image/svg/dentist-chair.svg'
import toothbrush from '../../assets/image/svg/toothbrush.svg'

const LoadingWrapper = styled('div')`
    ${({ theme }) => `
      position:absolute;
      left:0;
      top: 0;
      width: 100%;
      height:100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(225, 224, 224, 0.9);
      z-index: 100;
  `}
`

type Props = {
    seconds: number
}

const AnimatedIcon = styled('img')<Pick<Props, 'seconds'>>`
    ${({ theme, seconds }) => `
      height: 3rem;
      margin-left: 0.5rem;
      animation: MoveUpDown ${seconds}s ease-in-out alternate infinite;

    @keyframes MoveUpDown {
      0%, 100% {  
        opacity: 1;
        margin-bottom: 0.5rem;
      }
      50% {
        margin-bottom: 0;
        opacity: 0.5;
      }
    }
  `}
`

function Loading() {
    return (
        <LoadingWrapper>
            <AnimatedIcon seconds={0.7} src={cavity} alt='cavity' />
            <AnimatedIcon seconds={0.8} src={dentalRecord} alt='dentalRecord' />
            <AnimatedIcon seconds={0.9} src={dentistChair} alt='dentistChair' />
            <AnimatedIcon seconds={1} src={toothbrush} alt='toothbrush' />
        </LoadingWrapper>
    )
}

export default Loading
