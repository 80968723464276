// Actions
import {
    RESET_REGISTER_FORM,
    SAVING_BOX,
    SAVING_BOX_ADDRESS,
    SAVING_RENT,
    SAVING_USER,
} from '../../actions/registerForms/types'

// Types
import { RegisterFormsIT } from './interface'

export const INITIAL_STATE: RegisterFormsIT = {
    boxes: {
        data: [],
    },
    boxAddress: {
        name: '',
        position: {},
    },
    rentInfo: {
        data: [],
    },
    personalData: {
        _id: null,
        name: '',
        phone: '',
        email: '',
    },
}

function registerForms(state: RegisterFormsIT = INITIAL_STATE, action: any) {
    switch (action.type) {
        case SAVING_BOX:
            return {
                ...state,
                boxes: {
                    ...state.boxes,
                    data: action?.payload,
                },
            }
        case SAVING_BOX_ADDRESS:
            return {
                ...state,
                boxAddress: {
                    ...state.boxAddress,
                    name: action?.payload?.name,
                    position: action?.payload?.position,
                },
            }
        case SAVING_USER:
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    _id: action?.payload?._id,
                    name: action?.payload?.name,
                    email: action?.payload?.email,
                    phone: action?.payload?.phone,
                },
            }
        case SAVING_RENT:
            return {
                ...state,
                rentInfo: {
                    data: action?.payload,
                },
            }
        case RESET_REGISTER_FORM:
            return {
                state: INITIAL_STATE,
            }
        default:
            return state
    }
}

export default registerForms
