// Modules
import React from 'react'
import { Grid, Container, Breadcrumbs } from '@mui/material'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Icons
import { faHome } from '@fortawesome/free-solid-svg-icons'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

// Components
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

// Components
import { BreadcrumbItem } from '../../components'

const ContentWrapper = styled('div')`
    ${({ theme }) => `
        background: ${theme?.colors?.white};
        font-family: "Raleway", sans-serif;
        color: ${theme?.colors?.black};
        margin-top: ${theme?.spacing?.spc50};
        margin-bottom: ${theme?.spacing?.spc50};
        
        p{
            color: ${theme?.colors?.black};
            font-size: 17px;
        }
    `}
`

const breadcrumbs = [
    <BreadcrumbItem text={<FontAwesomeIcon icon={faHome} />} link={'/'} />,
    <BreadcrumbItem text={'Términos y condiciones'} />,
]

function TermsAndConditions() {
    return (
        <div style={{ background: '#fff' }}>
            <Header />
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '6rem' }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '2rem' }}>
                        <ContentWrapper>
                            <div style={{ textAlign: 'center' }}>
                                <h3 style={{ fontSize: '31px', color: '#161616', fontWeight: 'bold' }}>
                                    Términos y condiciones de uso de la plataforma Fliplt.work
                                </h3>
                            </div>
                            <p>
                                El presente sitio web, ubicado en la dirección www.https://flipit.gux.cl/, los servicios prestados
                                por su intermedio, y cualquiera de las páginas que se pueda acceder a través del mismo
                                –en adelante el <strong>‘Sitio’</strong> o la <strong>‘Plataforma’</strong>, está
                                provisto por <strong>Fliplt SpA-</strong> en adelante <strong>‘Fliplt’</strong> o{' '}
                                <strong>‘La Empresa’</strong>.
                            </p>
                            <p>
                                La <strong>Empresa</strong> declara que la <strong>Plataforma</strong> y su acceso se
                                encuentran condicionados a la aceptación completa por parte del <strong>Usuario</strong>{' '}
                                de los términos y condiciones que se expresan a continuación:
                            </p>
                            <p> </p>
                            <ul>
                                <li>
                                    El acceso a la <strong>Plataforma</strong>, en forma directa o indirecta, su uso y/o
                                    la descarga de información contenida en él supone que el <strong>Usuario </strong>de
                                    la misma acepta los presentes términos y condiciones en todas sus partes. Quien no
                                    aceptare los presentes términos y condiciones, deberá abstenerse de acceder a este{' '}
                                    <strong>Sitio</strong>, ya sea directa o indirectamente y de utilizar cualquier
                                    información o servicio provisto por el mismo.
                                </li>
                            </ul>
                            <p> </p>
                            <ul>
                                <li>
                                    Siempre que en estos Términos y Condiciones se haga referencia al{' '}
                                    <strong>‘Usuario’ </strong>se entenderá por tal a cualquier persona natural o
                                    jurídica, agrupación sin personalidad legal, o representante en cualquier forma de
                                    los mismos, que use los servicios y/o información contenidas en la{' '}
                                    <strong>Plataforma </strong>estará sujeto a los términos y condiciones que rigen
                                    para la utilización de los Servicios de Recaudación que se describen en el presente
                                    documento.
                                </li>
                            </ul>
                            <p> </p>
                            <ul>
                                <li>
                                    Los <strong>Usuarios</strong> de la <strong>Plataforma </strong>son de dos tipos:
                                    (i) el <strong>Usuario </strong>que solicita el servicio de recaudación, en adelante{' '}
                                    <strong>‘Usuario Mandante’</strong>, y (ii) el <strong>Usuario</strong> que paga el
                                    monto a recaudar, en adelante <strong>‘Usuario Pagador&#8217;</strong>.
                                </li>
                            </ul>
                            <p> </p>
                            <ul>
                                <li>
                                    En estas Condiciones Generales se describen los derechos y responsabilidades del{' '}
                                    <strong>Usuario </strong>y de <strong>Fliplt </strong>por utilizar el servicio de
                                    recaudación a través de Internet, al combinar: (i) dispositivos móviles (smartphones
                                    y otros) y/o dispositivos tradicionales (Computador personal, Notebook y otros),
                                    (ii) el uso del cupo que el <strong>Usuario </strong>mantenga en sus tarjetas de
                                    crédito y débito bancarias procesables por Transbank® y o la{' '}
                                    <strong>Plataforma</strong>, (iii) tecnología web, el <strong>Sitio</strong> y
                                    cualquier producto o servicio relacionado que ofrezca la{' '}
                                    <strong>Plataforma.</strong>
                                </li>
                            </ul>
                            <p> </p>
                            <p>
                                <strong>
                                    El Usuario declara en este acto haber leído, entender y aceptar todas las
                                    condiciones establecidas en estas Condiciones Generales, previo a su registro como
                                    Usuario de la Plataforma.
                                    <br />
                                </strong>
                                <br />
                                <strong>
                                    CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES
                                    TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LA
                                    PLATAFORMA.
                                </strong>
                            </p>
                            <p> </p>
                            <p>
                                <strong>1- Registro</strong>
                            </p>
                            <p>
                                <strong>Registro</strong>
                            </p>
                            <p>
                                {' '}
                                Todo Usuario que desee utilizar el Servicio de Recaudación debe registrarse en la
                                Plataforma, con lo cual declara aceptar íntegramente estas Condiciones Generales. Para
                                registrarse es obligatorio completar el formulario de registro en todos sus campos con
                                datos válidos e información personal exacta, precisa y verdadera (“Datos Personales”).
                                El Usuario asume el compromiso de actualizar los Datos Personales conforme resulte
                                necesario. <strong>Fliplt</strong> <strong>no</strong> se responsabiliza por la certeza
                                de los Datos Personales provistos por sus usuarios. Los Usuarios garantizan y responden,
                                en cualquier caso, de la veracidad, exactitud, integridad, vigencia y autenticidad de
                                los Datos Personales ingresados. Toda la información y los Datos Personales ingresados
                                por el Usuario tienen carácter de declaración jurada. <strong>Fliplt</strong> se reserva
                                el derecho de rechazar una solicitud de registro o de cancelar o suspender, temporal o
                                definitivamente una cuenta sin expresión de causa, y especialmente, en caso de detectar
                                incongruencias o inconsistencias en la información provista por un Usuario o en caso de
                                detectar actividades sospechosas, sin que tal decisión genere para el Usuario derechos
                                de indemnización o resarcimiento.<strong> </strong>
                            </p>
                            <p>
                                <strong>El proceso</strong>
                            </p>
                            <p>
                                <strong> </strong>Al ingresar a <strong>Fliplt</strong> el Usuario Mandante podrá emitir
                                una Orden de Recaudación dirigida a <strong>Fliplt</strong>, para que éste recaude en su
                                nombre, estableciendo en dicha orden: correo electrónico del Usuario Pagador, monto de
                                recaudación y monto de la comisión que pagará a <strong>Fliplt</strong> por el uso de la
                                Plataforma.
                            </p>
                            <p>
                                La Plataforma creará una Notificación de Recaudación al Usuario Pagador, identificado en
                                la Orden de Recaudación, para que éste proceda al pago, simultáneamente le enviará un
                                correo electrónico con la información y registrará este hecho en el Sitio.
                            </p>
                            <p>
                                La Plataforma permitirá al Usuario Pagador que éste pague usando tarjetas de crédito o
                                débito desde cualquier computador personal o dispositivo móvil conectado a Internet,
                                permitiendo la realización de transacciones con dichas tarjetas en cualquier tiempo y
                                lugar gracias a la ubicuidad de los dispositivos móviles.
                            </p>
                            <p>
                                Una vez que el Usuario Pagador paga en el Sitio e <strong>Fliplt</strong> recauda en
                                nombre del Usuario Mandante, la Plataforma envía un correo electrónico al Usuario
                                Mandante donde se le informa una fecha estimada de abono en su cuenta bancaria, el monto
                                a ser depositado descontada la comisión de <strong>Fliplt</strong> y registra este hecho
                                en el Sitio.
                            </p>
                            <p>
                                En caso de existir problemas derivados de acciones de terceros que impliquen postergar
                                la fecha estimada de abono, la Plataforma enviará un correo electrónico informando de la
                                nueva fecha estimada de abono en la cuenta bancaria del Usuario Mandante.
                            </p>
                            <p>
                                Una vez que se cumpla la fecha de pago, no existiendo problemas derivados de acciones de
                                terceros, la Plataforma enviará a pago la recaudación a la cuenta bancaria del Usuario
                                Mandante. Simultáneamente, generará un correo electrónico al Usuario Pagador informando
                                que se ha enviado a pago lo recaudado y registrará este hecho en el Sitio.
                            </p>
                            <p>
                                En el caso de que la recepción del pago sea rechazado por el banco del Usuario Mandante,
                                la Plataforma le enviará un correo electrónico al Usuario Mandante informando del
                                rechazo y registrará este hecho en el Sitio. Será responsabilidad exclusiva del Usuario
                                Mandante hacer todas las gestiones ante su banco a objeto de destrabar la recepción del
                                dinero, informando a <strong>Fliplt</strong> a través de la Plataforma de la solución
                                del problema generado.
                            </p>
                            <p>
                                Toda transacción ocurrida en Fliplt está sujeta un análisis de comportamiento, en
                                particular, cada nuevo Usuario Mandante registrado en la Plataforma mandata a Fliplt
                                para que supervise de manera particular sus transacciones.
                            </p>
                            <p>
                                Con el fin de que Fliplt cumpla con su servicio de recaudación y, en el caso de que
                                Fliplt en la ejecución de su proceso de análisis de transacciones ocurridas en la
                                Plataforma, detecte una actividad que tenga comportamiento anómalo, o una transacción
                                sea sospechosa de un acto ilícito o el contenido de la transacción investigada no se
                                ajuste a estos Términos y condiciones, entonces, el Usuario Mandante:
                            </p>
                            <ol>
                                <li>Autoriza a Fliplt investigar en detalle sus transacciones.</li>
                                <li>
                                    Se obliga a entregar con la máxima celeridad toda documentación o información que
                                    Fliplt le solicite relativa a las transacciones efectuadas o antecedentes relativos
                                    a su negocio.
                                </li>
                                <li>
                                    Autoriza a Fliplt retener los montos involucrados en ellas, mientras dure la
                                    investigación.
                                </li>
                                <li>
                                    Mandata a Fliplt a que le transfiera los dineros de las transacciones investigadas
                                    una vez que el Usuario Pagador informa por escrito a Fliplt o hace llegar a Fliplt
                                    documentación donde se consigna la conformidad con el servicio brindado y/o el
                                    producto pagado.
                                </li>
                            </ol>
                            <p>
                                <strong> </strong>
                            </p>
                            <p>
                                <strong>Acceso</strong>
                            </p>
                            <p>
                                El Usuario accederá a la Plataforma mediante el ingreso de su dirección de correo
                                electrónico (e-mail) y su clave de seguridad personal (“Clave de Ingreso”). El Usuario
                                se obliga a mantener la confidencialidad de todas sus Claves. En virtud de ello, el
                                Usuario será el único y exclusivo responsable por todas las operaciones efectuadas en su
                                nombre en la Plataforma.
                            </p>
                            <p>
                                <strong> </strong>
                            </p>
                            <p>
                                <strong>El pago y terceros relevantes</strong>
                            </p>
                            <p>
                                El pago con tarjeta se realiza utilizando la Plataforma y los servicios de Transbank®:
                                ‘WebPay Plus®’, contratados por <strong>Fliplt</strong> , eligiendo uno de estos
                                servicios en función de su disponibilidad en la Plataforma y la decisión el Usuario
                                Pagador al momento de realizar el pago. <strong>Fliplt</strong> es un comercio enrolado
                                en Transbank®.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Moneda</strong>
                            </p>
                            <p>
                                Cualquier referencia a dinero en la Plataforma se entenderá hecha en la moneda de curso
                                legal -pesos chilenos-.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Usuarios Mandantes</strong>
                            </p>
                            <p>
                                El registro de los Usuarios Mandantes se realiza con el fin de que{' '}
                                <strong>Fliplt</strong> disponga de la información bancaria necesaria para que el
                                Usuario Mandante reciba el pago en su cuenta corriente bancaria o cuenta vista. La
                                Plataforma obliga al Usuario Mandante a ingresar los datos necesarios para realizar una
                                transferencia electrónica: correo electrónico válido y una clave secreta asociada a él,
                                Nombre(s) y Apellido(s), RUT, Banco, Tipo de cuenta, Número de cuenta corriente o Número
                                de cuenta vista (
                                <u>
                                    donde el Usuario Mandante recibirá los pagos descontada la comisión de{' '}
                                    <strong>Fliplt</strong>
                                </u>
                                ) , adicionalmente, la Plataforma solicita con carácter de obligatorio un número
                                telefónico de contacto válido.
                            </p>
                            <p> </p>
                            <p>
                                <strong>
                                    <u>Fliplt</u>
                                </strong>{' '}
                                generará una factura de venta exenta asociada a la comisión por los servicios de
                                recaudación otorgados a través de la plataforma.
                            </p>
                            <p> </p>
                            <p>
                                En el caso de que el Usuario Mandante desee que <strong>Fliplt</strong> le emita una
                                factura de venta exenta en lugar de una boleta de venta exenta por los servicios
                                prestados a través de la Plataforma, el Usuario Mandante está obligado a agregar a su
                                registro: Razón social (si corresponde), Dirección física, Giro, Rut e indicar en el
                                Sitio que desea recibir una factura exenta.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Usuario Pagador</strong>
                            </p>
                            <p>
                                El registro del Usuario Pagador exige ingresar: un correo electrónico valido y una clave
                                secreta asociado a él.
                            </p>
                            <p> </p>
                            <p>
                                <strong>2- Mandato</strong>
                            </p>
                            <p>
                                <strong>Mandato Irrevocable.</strong> El Usuario Mandante otorga mandato comercial
                                irrevocable en los términos establecidos en el artículo 241 del Código de Comercio a{' '}
                                <strong>Fliplt</strong> para que éste proceda a <strong>recaudar</strong> los dineros
                                informados por el Usuario Mandante a través de una la Orden de Recaudación emitida por
                                él, al momento de usar el servicio de recaudación. <strong>Fliplt</strong> está
                                facultado para depositar los dineros del producto de la recaudación en la cuenta
                                bancaria que haya registrado el Usuario Mandante, descontando previamente el dinero que
                                corresponde a <strong>Fliplt</strong> por pago de sus <u>servicios de recaudación.</u>
                            </p>
                            <p>
                                <u> </u>
                            </p>
                            <p>
                                <strong>Alcance de responsabilidad</strong>
                            </p>
                            <p>
                                <strong>Fliplt</strong> no será responsable ni garantizará el cumplimiento de las
                                obligaciones que hubiesen asumido y acordado los Usuarios entre sí o con terceros en
                                relación al pago y recaudación de los dineros realizados a través de la Plataforma. El
                                Usuario reconoce y acepta que al realizar transacciones con otros Usuarios o terceros lo
                                hace por su propia voluntad, ajeno al servicio de recaudación provisto por la
                                Plataforma, prestando su consentimiento libremente y bajo su propio riesgo y
                                responsabilidad. En ningún caso <strong>Fliplt</strong> será responsable por lucro
                                cesante, o por cualquier otro daño y/o perjuicio que haya podido sufrir el Usuario,
                                debido a las transacciones realizadas.
                                <br />
                                En virtud que <strong>Fliplt</strong> es ajeno a la obligación que dio origen a la Orden
                                de Recaudación, <strong>Fliplt</strong> no será responsable ni verificará las causas,
                                importe o cualquier otra circunstancia relativa a dicha Orden de Recaudación, así como
                                respecto de la existencia, calidad, cantidad, funcionamiento, estado, integridad o
                                legitimidad de los bienes o servicios ofrecidos, adquiridos o enajenados por los
                                Usuarios.{' '}
                                <u>
                                    La Plataforma sólo facilita la recaudación de los pagos generados en una transacción
                                    anterior al servicio prestado por ella.
                                </u>
                                <br />
                                En caso que uno o más Usuarios o algún tercero inicien cualquier tipo de reclamo o
                                acciones legales contra otro u otros Usuarios, todos y cada uno de los Usuarios
                                involucrados en dichos reclamos o acciones{' '}
                                <u>
                                    eximen de toda responsabilidad a <strong>Fliplt</strong>
                                </u>{' '}
                                y a sus directores, gerentes, empleados, agentes, operarios, representantes y apoderados
                                y renuncian a ejercer las acciones legales de cualquier naturaleza que les pudieran
                                corresponder.
                            </p>
                            <p> </p>
                            <p>
                                <strong>3- Información y correos electrónicos falsos</strong>
                            </p>
                            <p>
                                Toda información debe ser siempre verificada por el Usuario a través de la Plataforma,
                                accediendo con su dirección de correo electrónico (e-mail) y clave de seguridad
                                personal.
                                <br />
                                <strong>Fliplt</strong> no será responsable por las conductas del Usuario que se
                                originen o vinculen con información contenida en correos electrónicos falsos o que
                                aparenten provenir de <strong>Fliplt</strong> o la Plataforma que sean, enviados por
                                terceros sin relación con <strong>Fliplt</strong> ni con sus sociedades relacionadas;
                                como así tampoco responderán por las consecuencias dañosas de tales conductas, ni por la
                                circulación de los mencionados correos electrónicos falsos.
                            </p>
                            <p>
                                <strong>Fliplt solo acepta pagos emitidos en Chile</strong>, en el caso de ser pagos
                                internacionales o de origen sospechoso, <strong>Fliplt</strong>{' '}
                                <strong>se reserva el derecho de realzar la anulación del pago</strong>
                            </p>
                            <p>
                                <strong>4- Condiciones Generales de Contratación</strong>
                            </p>
                            <p>
                                <strong>Capacidad</strong>
                            </p>
                            <p>
                                Sólo podrán emitir órdenes de recaudación y contratar la utilización de servicios de
                                recaudación aquellas personas que tengan capacidad legal para contratar y estén
                                debidamente registrados como Usuarios en la Plataforma. No podrán registrarse ni
                                utilizar los servicios personas que no tengan capacidad para contratar o aquellos
                                Usuarios que hayan sido suspendidos o inhabilitados en la Plataforma, temporal o
                                definitivamente.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Declaraciones del Usuario</strong>
                            </p>
                            <p>
                                Usos Prohibidos. El Usuario manifiesta que el objeto por el cual se celebra la Orden de
                                Recaudación no infringe ninguna ley aplicable, ni es por un servicio, venta o
                                transmisión que está prohibida por la ley o por estos Términos y Condiciones Generales,
                                tales como y sin limitarse a: (i) juegos de azar, apuestas, etc.; (ii) tráfico de armas,
                                de personas, de animales, etc.; (iii) lavado de dinero y/o terrorismo; (iv) pornografía,
                                prostitución o pedofilia; y/o (v) cualquier tipo de actividad que pueda ser considerada
                                fraudulenta o ilegal o sospechosa de serlo.
                                <br />
                                El Usuario no utilizará la Plataforma para transmitir material que constituya un delito
                                o bien que pueda dar lugar, directa o indirectamente a responsabilidades civiles o que
                                infrinjan estos Términos y Condiciones Generales.
                                <br />
                                Si <strong>Fliplt</strong> considera que hay una sospecha o indicio de la utilización de
                                la Plataforma para alguna actividad prohibida por la ley o estos Términos y Condiciones
                                Generales, podrá rechazar, cancelar o suspender una Orden de Recaudación, y/o bloquear
                                temporalmente el acceso a un Usuario y/o la utilización o disponibilidad de
                                funcionalidades y/o cancelar definitivamente un Registro de Usuario. En tal caso, el
                                Usuario responderá por los daños y perjuicios que pueda haber ocasionado a{' '}
                                <strong>Fliplt</strong>, sus controlantes, controladas, filiales o subsidiarias,
                                funcionarios, empleados, directores, agentes, y/o empleados, a cuyos efectos{' '}
                                <strong>Fliplt</strong> se reserva el derecho de iniciar las acciones judiciales o
                                extrajudiciales que estime pertinentes.
                                <br />
                                El Usuario será exclusiva e ilimitadamente responsable por los perjuicios que su
                                conducta pueda causar a <strong>Fliplt</strong> o a los restantes Usuarios de la
                                Plataforma.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Declaración del Usuario Pagador</strong>
                            </p>
                            <p>
                                El Usuario Pagador, en el acto de pagar el dinero a recaudar, establece su conformidad
                                con el producto adquirido y o servicio recibido, cuya transacción anterior dio origen a
                                la recaudación a través de la Plataforma.
                            </p>
                            <p>
                                <strong>
                                    <u>Fliplt</u>
                                </strong>
                                <u> no es entidad financiera.</u> Se deja expresamente aclarado que{' '}
                                <strong>Fliplt</strong> no es una entidad financiera ni presta al Usuario ningún
                                servicio bancario o cambiario,{' '}
                                <strong>
                                    sólo brinda un servicio de recaudación por orden de los Usuarios Mandantes
                                </strong>{' '}
                                según las condiciones establecidas en los Términos y Condiciones del servicio que da
                                cuenta este instrumento.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Tarifa por Servicio de Recaudación</strong>
                            </p>
                            <p>
                                El Usuario Mandante acepta pagar una comisión por el servicio de recaudación según el
                                listado de Tarifas vigentes que es expuesto en la Plataforma, facultando a{' '}
                                <strong>Fliplt</strong> en forma expresa para que deduzca al momento de la recaudación
                                los dineros equivalentes al porcentaje acordado de cada transacción comercial por
                                concepto de pago de los servicios de recaudación.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Seguridad y Confidencialidad de datos personales</strong>
                            </p>
                            <p>
                                El Usuario declara conocer y aceptar la política de confidencialidad y se obliga a
                                guardar absoluta reserva sobre los datos aquí proporcionados. Toda la información
                                personal proporcionada por el Usuario, incluidos los datos de información bancaria, es
                                de responsabilidad exclusiva de quien la aporta. La información ingresada por el Usuario
                                será utilizada por <strong>Fliplt</strong> para registrarlo en los programas y
                                servicios, procesar y darle seguimiento a los servicios, contestar correos electrónicos
                                y proporcionar información con respecto a sus movimientos y respecto de los bienes y
                                servicios contratados. <strong>Fliplt</strong> se reserva el derecho de usar esta
                                información para enviar al Usuario correos electrónicos o comunicarse telefónicamente él
                                con información relativa a sus operaciones en la Plataforma, como también para enviar
                                información sobre promociones de productos o servicios. El Usuario siempre podrá
                                solicitar el cese de envío de los correos electrónicos promocionales.{' '}
                                <strong>Fliplt</strong> no venderá, alquilará ni negociará con otras empresas la
                                información personal de los Usuarios. Estos datos serán utilizados para prestar el
                                Servicio que da cuenta este instrumento. Sin embargo, <strong>Fliplt</strong> podrá
                                compartir dicha información con proveedores de servicios de valor agregado que se
                                integren a la Plataforma para atender necesidades de los Usuarios, relacionadas con los
                                servicios que suministra la misma. La Plataforma, en términos de seguridad
                                transaccional, utiliza la infraestructura que provee Transbank®, por tanto, no almacena
                                el código de seguridad de las tarjetas de crédito, por tanto siempre solicitará dicho
                                código. <strong>Fliplt</strong> no es responsable por la pérdida o el daño eventual que
                                puedan sufrir los archivos enviados al Usuario, ya sea por problemas técnicos, hecho
                                suyos o de sus dependientes, razón por la que es responsabilidad del Usuario el respaldo
                                de la información que se le envía.
                                <br />
                                <strong>Fliplt</strong> hará sus mejores esfuerzos para mantener la confidencialidad y
                                seguridad de que trata esta sección, pero no responderá por perjuicios que se puedan
                                derivar de la violación de dichas medidas por parte de terceros que utilicen las redes
                                públicas o el Internet para acceder a dicha información.
                            </p>
                            <p>
                                <strong> </strong>
                            </p>
                            <p>
                                <strong>
                                    Modificaciones de los Términos y Condiciones del Servicio de Recaudación
                                </strong>
                            </p>
                            <p>
                                <strong>Fliplt</strong> podrá modificar en cualquier momento los Términos y Condiciones
                                del Servicio de Recaudación. <strong>Fliplt</strong> notificará los cambios al Usuario
                                publicando una versión actualizada de dichos Términos y Condiciones en el Sitio con
                                expresión de la fecha de la última modificación y enviará un correo electrónico a la
                                dirección del Usuario registrada en la Plataforma. Los Términos y Condiciones del
                                Servicio de recaudación modificados serán aplicables a toda Orden de recaudación que se
                                celebre con posterioridad a la notificación al Usuario.
                            </p>
                            <p>
                                <strong> </strong>
                            </p>
                            <p>
                                <strong>Terminación del Contrato</strong>
                            </p>
                            <p>
                                <strong>Fliplt</strong> y el Usuario del Servicio podrán en cualquier oportunidad de
                                vigencia del presente contrato terminarlo sin expresión de causa alguna, lo que
                                implicará la eliminación del Registro del Usuario. A los efectos de ejercer esta
                                facultad, es necesario que la parte que pretende la terminación del contrato{' '}
                                <u>no adeude a la otra ni a terceros involucrados</u> en las operaciones el cumplimiento
                                de alguna obligación.
                                <br />
                                Asimismo, <strong>Fliplt</strong> podrá, en caso de incumplimiento del Usuario a las
                                condiciones del presente contrato o a la legislación aplicable en la materia, dar por
                                terminada la prestación del Servicio sin ningún tipo de aviso previo, reservándose el
                                derecho de reclamar los daños y perjuicios que tal incumplimiento le haya causado.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Interrupción del servicio de la Plataforma</strong>
                            </p>
                            <p>
                                Se deja constancia que la Plataforma podría interrumpirse temporalmente entre múltiples
                                causas, por fenómenos de la naturaleza, hechos de terceros, por razones de caso fortuito
                                o fuerza mayor tales como sismos, pandemias, cortes de energía eléctrica y o servicio
                                telefónico y o líneas de transmisión de datos, intervenciones de redes por parte de
                                terceros, no funcionamiento de redes públicas y o privadas, actos vandálicos o
                                terroristas, huelgas u otros similares u otras causas no imputables a{' '}
                                <strong>Fliplt</strong>.
                            </p>
                            <p> </p>
                            <p>
                                <strong>Documentación</strong>
                            </p>
                            <p>
                                El Usuario podrá hacer copias de la presente documentación. El Usuario autoriza en forma
                                irrevocable a <strong>Fliplt</strong> y/o a quien <strong>Fliplt</strong> designe, a
                                grabar las operaciones del Usuario relativas los servicios prestados por la Plataforma,
                                y a utilizar dichas grabaciones como medio probatorio ante autoridades administrativas
                                y/o judiciales. Las constancias emitidas por <strong>Fliplt</strong> serán consideradas
                                prueba suficiente del cumplimiento de las instrucciones: órdenes de recaudación,
                                notificaciones de recaudación, correos electrónicos, conversaciones telefónicas,
                                registros computacionales e informes sustituyen la necesidad de cualquier recibo y son
                                plenamente oponibles al Usuario. A tales efectos, el Usuario y <strong>Fliplt</strong>{' '}
                                acuerdan otorgarle valor probatorio a las constancias que se emitan como consecuencia de
                                las aprobaciones o instrucciones dadas por el Usuario, con el alcance establecido en la
                                legislación vigente.
                            </p>
                            <p>
                                <strong> </strong>
                            </p>
                            <p>
                                <strong>Notificaciones</strong>
                            </p>
                            <p>
                                Serán válidas todas las notificaciones realizadas a los Usuarios por la Plataforma y{' '}
                                <strong>Fliplt</strong> en la dirección de correo electrónico principal registrada por
                                éstos.
                            </p>
                            <p>
                                <strong> </strong>
                            </p>
                            <p>
                                <strong>Anexos</strong>
                            </p>
                            <p>
                                Forma parte integral e inseparable de los Términos y Condiciones de Uso incorporados en
                                este acuerdo, el Listado de Tarifas Vigentes que pueden ser vistas en el Sitio.
                            </p>
                            <p>
                                <strong> </strong>
                            </p>
                            <p>
                                <strong>Jurisdicción y ley aplicable</strong>
                            </p>
                            <p>
                                Este acuerdo estará regido en todos sus puntos por las leyes vigentes en Chile.
                                Cualquier controversia derivada del presente acuerdo, su existencia, validez,
                                interpretación, alcance o cumplimiento, será sometida a la jurisdicción de los
                                tribunales ordinarios de la Ciudad de Santiago ante los cuales el Usuario y{' '}
                                <strong>Fliplt</strong> prorrogan competencia.
                            </p>
                        </ContentWrapper>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </div>
    )
}

export default TermsAndConditions
