import { createStore, combineReducers, applyMiddleware } from 'redux'
import { registerForms, loader } from './reducers'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

let intialState: any = {}
try {
    const storage: any = sessionStorage.getItem('global_state')
    intialState = storage ? JSON.parse(storage) : {}
} catch (error) {
    console.log('Get Storage Error', error)
}

const reducers = combineReducers({
    loader,
    registerForms,
})

const saver = (store: any) => (next: any) => (action: any) => {
    let stateToSave = store.getState()
    sessionStorage.setItem('global_state', JSON.stringify({ ...stateToSave }))
    next(action)
}

const store = createStore(reducers, intialState, composeWithDevTools(applyMiddleware(thunk, saver)))

export default store
