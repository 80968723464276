// Modules
import React from 'react'
import styled from 'styled-components'

// Images
import FlipItLogo from '../../assets/image/svg/logo.svg'
import Background from '../../assets/image/svg/bg_flipit.svg'

const ContentWrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    min-height: 100vh;
    display: flex;
    background-color: ${theme?.colors?.white};
    background-position: center;
    background-size: 100% auto;
    background-image: url(${Background});
  `}
`

const LeftWrapper = styled('div')`
    ${({ theme }) => `
    width: 35%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px){
      dipslay: none;
      width: 0;
    }
  `}
`

const LinkGoBack = styled('a')`
    ${({ theme }) => `
      position: absolute;
      top:20px;
      left: 20px;
      color: ${theme?.colors?.secondBase};
      font-size: ${theme?.fontSizes?.font22};
      font-family: raleway;
  `}
`

const LogoFlipIt = styled('img')`
    ${({ theme }) => `
    width: 220px;
    height: auto;
    
    @media screen and (max-width:768px){
      display: none;
    }
  `}
`

const RightWrapper = styled('div')`
    ${({ theme }) => `
    width: 75%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    @media screen and (max-width: 768px){
      width: 100%;
      justify-content: center;
    }
  `}
`

function FormContentWrapper({ children }: any) {
    return (
        <ContentWrapper>
            <LinkGoBack href='https://flipit.gux.cl/'> {'<'} Ir al inicio</LinkGoBack>
            <LeftWrapper>
                <a href='https://flipit.gux.cl/' style={{ textDecoration: 'none' }}>
                    <LogoFlipIt src={FlipItLogo} alt='FlipIt' />
                </a>
            </LeftWrapper>
            <RightWrapper>{children}</RightWrapper>
        </ContentWrapper>
    )
}

export default FormContentWrapper
