import { handleActions } from 'redux-actions';
import { START_LOADING, END_LOADING } from '../actions/loader';

export const INITIAL_STATE = {
  loading: false,
};

export default handleActions({
  [START_LOADING]: () => {
    return { loading: true };
  },
  [END_LOADING]: () => {
    return { loading: false };
  }
}, INITIAL_STATE);