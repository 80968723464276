// Modules
import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form, Field, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Yup from 'yup'

// Icons
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

// Components
import Card from '../../../components/Card'
import { FormContentWrapper } from '../../../components'

// Routes
import history from '../../../services/history'

// Redux
import { changePersonalData } from '../../../redux/actions/registerForms/action'

// Interfaces
import { FormFields, UserInputIT } from './interface'

// Services
import UserService from '../../../services/user/user'
import { BoxService } from '../../../services'

const FormWrapper = styled('div')`
    ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    form{
      width: 100%;
    }
  `}
`

const PhoneInput = styled('div')`
    ${({ theme }) => `
    width: 105%;
    display: flex;
    align-items: center;
    border: 1px solid ${theme?.colors?.greyLight};
    
    input{
        border: none !important;
    }

    svg{
        color: ${theme?.colors?.greyBase};
        font-size: ${theme?.fontSizes?.font18};
        padding: 0 ${theme?.spacing?.spc12};
    }
  `}
`

const FormLabel = styled('div')`
    ${({ theme }) => `
    color: ${theme?.colors?.black};
    font-family: raleway;
    font-size: ${theme?.fontSizes?.font16};
    margin-bottom: ${theme?.spacing?.spc20};
  `}
`

const FormFieldControl = styled('div')`
    ${({ theme }) => `
    width: 80%;
    margin-left: 10%;
    margin-bottom: ${theme?.spacing?.spc12};

    input{
      width: 100%;
      height: 1.5 rem;
      border: 1px solid ${theme?.colors?.greyLight};
      padding: ${theme?.spacing?.spc10};
      outline: none;
      font-family: raleway;
      color: ${theme?.colors?.black};
      font-size: ${theme?.fontSizes?.font16};
    }
  `}
`

const CountryCode = styled('span')`
    ${({ theme }) => `
    font-family: raleway;
    color: ${theme?.colors?.greyBase};
    font-size: ${theme?.fontSizes?.font16};
    padding:${theme?.spacing?.spc10};
  `}
`

const CountPhoneNumbers = styled('span')`
    ${({ theme }) => `
    font-family: raleway;
    color: ${theme?.colors?.greyBase};
    font-size: ${theme?.fontSizes?.font14};
  `}
`

const LinkTermsAndConditions = styled('a')`
    ${({ theme }) => `
    color: ${theme?.colors?.secondBase};
    font-family: raleway;
    font-size: ${theme?.fontSizes?.font16};
  `}
`

const ErrorDiv = styled('div')`
    ${({ theme }) => `
    color: ${theme?.colors?.red};
    font-size: ${theme?.fontSizes?.font14};
  `}
`

const SignupSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Demasiado corta').max(50, 'Demasiado grande!').required('Campo obligatório!'),
    email: Yup.string().email('Correo inválido').required('Campo obligatório!'),
    phone: Yup.number().typeError('Solo se permiten numeros').required('Campo obligatório!'),
    password: Yup.string().min(3, 'Demasiado corta').required('Campo obligatório!'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
        .required('Campo obligatório!'),
    termsAndConditions: Yup.boolean().oneOf([true], 'Debe aceptar los términos y condiciones'),
})

function PersonalData() {
    // States
    const [isLoading, setIsLoading] = useState(false)

    const formRef = 'personalData-form'

    const {
        registerForms: {
            personalData: { _id, name, phone, email },
            boxes: { data: boxesData },
        },
    }: any = useSelector(state => state)

    // Dispatch
    const dispatch = useDispatch()

    // Methods
    const handleSubmit = async (values: FormFields) => {
        setIsLoading(true)
        const userService = new UserService()
        const data: UserInputIT = {
            name: values.name,
            email: values.email,
            phone: `+56 ${values.phone}`,
            password: values.password,
        }
        let resp: any = {}
        if (_id) {
            resp = await userService.updateUser(_id, data)
        } else {
            resp = await userService.addUser(data)
            const boxService = new BoxService()
            await boxService.addUserInBox(boxesData[0]._id, resp?._id)
        }
        dispatch(changePersonalData(resp?._id, resp?.firstName, resp?.email, resp?.phone))
        setIsLoading(false)
        history.push('/onboarding/summary')
    }

    return (
        <FormContentWrapper>
            <Card
                mark={'Déjanos'}
                title={'tu datos'}
                current={7}
                backLink={'/onboarding/days-rent'}
                formRef={formRef}
                isLoading={isLoading}
            >
                <FormWrapper>
                    <Formik
                        initialValues={{
                            name,
                            email,
                            phone,
                            password: '',
                            confirmPassword: '',
                            termsAndConditions: false,
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={values => {
                            handleSubmit(values)
                        }}
                    >
                        {({ errors, touched, values, setFieldValue }: FormikProps<FormFields>) => (
                            <Form id={formRef}>
                                <FormFieldControl>
                                    <FormLabel>Nombre del contacto</FormLabel>
                                    <Field name='name' type='text' />
                                    {errors.name && touched.name ? <ErrorDiv>{errors.name}</ErrorDiv> : null}
                                </FormFieldControl>

                                <FormFieldControl>
                                    <FormLabel>Número de teléfono</FormLabel>
                                    <PhoneInput>
                                        <CountryCode>+56</CountryCode>
                                        <Field name='phone' placeholder='0000000000' maxlength='9' type='text' />
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                    </PhoneInput>
                                    <CountPhoneNumbers>{values.phone?.length}/9</CountPhoneNumbers>
                                    {errors.phone && touched.phone ? <ErrorDiv>{errors.phone}</ErrorDiv> : null}
                                </FormFieldControl>

                                <FormFieldControl>
                                    <FormLabel>Correo electrónico</FormLabel>
                                    <Field name='email' type='text' />
                                    {errors.email && touched.email ? <ErrorDiv>{errors.email}</ErrorDiv> : null}
                                </FormFieldControl>

                                <FormFieldControl>
                                    <FormLabel>Contraseña</FormLabel>
                                    <Field name='password' type='password' />
                                    {errors.password && touched.password ? (
                                        <ErrorDiv>{errors.password}</ErrorDiv>
                                    ) : null}
                                </FormFieldControl>

                                <FormFieldControl>
                                    <FormLabel>Confirmar contraseña</FormLabel>
                                    <Field name='confirmPassword' type='password' />
                                    {errors.confirmPassword && touched.confirmPassword ? (
                                        <ErrorDiv>{errors.confirmPassword}</ErrorDiv>
                                    ) : null}
                                </FormFieldControl>

                                <FormFieldControl style={{ marginTop: '2rem' }}>
                                    <Checkbox
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        style={{ color: '#21cea4' }}
                                        checked={values.termsAndConditions}
                                        onChange={e => {
                                            const value = e?.target?.checked
                                            setFieldValue('termsAndConditions', value)
                                        }}
                                    />
                                    <LinkTermsAndConditions
                                        href='https://https://flipit.gux.cl/terminos-y-condiciones/'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Acepto los términos y condiciones
                                    </LinkTermsAndConditions>
                                    {errors.termsAndConditions && touched.termsAndConditions ? (
                                        <ErrorDiv>{errors.termsAndConditions}</ErrorDiv>
                                    ) : null}
                                </FormFieldControl>
                            </Form>
                        )}
                    </Formik>
                </FormWrapper>
            </Card>
        </FormContentWrapper>
    )
}

export default PersonalData
