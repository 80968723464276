import api from '../../axios'
import { UserInputIT } from './interface'

export default class UserService {
    async addUser(formData: UserInputIT) {
        const resp = await api.post('/user/add', formData)
        const { data }: any = resp.data
        return data
    }

    async updateUser(_id: string, formData: UserInputIT) {
        const resp = await api.put(`/user/${_id}`, formData)
        const { data }: any = resp.data
        return data
    }
}
